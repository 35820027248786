import type {TimeZone} from 'timezones-list';
import moment from 'moment-timezone';

export const getUTCLabel = (timeZone: TimeZone): string => {
  const utcText = timeZone.label.split(' ')[1].replace('GMT', 'UTC');
  return utcText.substring(1, utcText.length - 1);
};

export const getTzCodeWithShortText = (tzCode: string): string => {
  const shortTzCode = getShortTzCodeText(tzCode);
  return `${getTzCodeText(tzCode)}${shortTzCode !== '' ? ' - ' : ''}${shortTzCode}`;
};

/**
 * Example: Asia - Karachi
 * Replaces "/" with " - "
 * Replaces "_" with " "
 * @param tzCode
 */
export const getTzCodeText = (tzCode: string): string => {
  return tzCode.replace(/\/|_/g, (match) => {
    return match === '/' ? ' - ' : ' ';
  });
};

/**
 * Gets the abbreviation for a country code like Asia - Karachi is "PKT"
 * @param tzCode
 */
export const getShortTzCodeText = (tzCode: string): string => {
  const abbreviation = moment.tz(tzCode).zoneAbbr();
  return isNaN(Number(abbreviation)) ? abbreviation : '';
};

export const getShortTzCodeWithUTCLabelFallBack = (timezone: TimeZone): string => {
  const shortTzCode = getShortTzCodeText(timezone.tzCode);
  return shortTzCode !== '' ? getShortTzCodeText(timezone.tzCode) : getUTCLabel(timezone);
};

export const getShortTzCodeWithUTCLabel = (timezone: TimeZone): string => {
  if (getShortTzCodeText(timezone.tzCode) !== '') {
    return `${getShortTzCodeText(timezone.tzCode)} (${getUTCLabel(timezone)})`;
  } else {
    return getUTCLabel(timezone);
  }
};

export const getUTCOffsetMinutesFromTimeZone = (timeZone: TimeZone): number => {
  const timeString = timeZone.utc;
  const [sign, time] = [timeString[0], timeString.slice(1)];
  const [hours, minutes] = time.split(':').map(Number);
  const totalMinutes = hours * 60 + minutes;
  return sign === '-' ? -totalMinutes : totalMinutes;
};
