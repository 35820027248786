import type {EventPreviewDateTime} from '@Components/event-wizard/preview/preview.types';
import {
  COUNTDOWN_DAYS_CONTAINER_ID,
  COUNTDOWN_HOURS_CONTAINER_ID,
  COUNTDOWN_ID,
  COUNTDOWN_MINS_CONTAINER_ID,
  COUNTDOWN_SECS_CONTAINER_ID,
  COUNTDOWN_TIME_ID,
  COVER_PHOTO_BACKGROUND_ID,
  COVER_PHOTO_CONTAINER_ID,
  COVER_PHOTO_ID,
  COVER_PHOTO_PLACEHOLDER_ICON_ID,
  CUSTOM_EVENT_COUNTDOWN_WIZARD_UPDATED,
  DATE_TIME_EMPTY_ID,
  DATE_TIME_END_SUBTITLE_ID,
  DATE_TIME_END_TITLE_ID,
  DATE_TIME_ICON_START_DATE_ID,
  DATE_TIME_ICON_START_MONTH_ID,
  DATE_TIME_MOBILE_END_TITLE_ID,
  DATE_TIME_MOBILE_START_TITLE_ID,
  DATE_TIME_MULTIDAY_ID,
  DATE_TIME_RECURRING_DAILY_ID,
  DATE_TIME_RECURRING_MONTHLY_ID,
  DATE_TIME_RECURRING_WEEKLY_MULTI_ID,
  DATE_TIME_RECURRING_WEEKLY_SINGLE_ID,
  DATE_TIME_SINGLE_DAY_ID,
  DATE_TIME_START_SUBTITLE_ID,
  DATE_TIME_START_TITLE_ID,
  DATE_TIME_TIMEZONE_ID,
  DESCRIPTION_ID,
  ORGANISER_EMAIL,
  ORGANISER_NAME,
  ORGANISER_PHONE,
  ORGANISER_PROFILE_PICTURE,
  ORGANISER_SOCIAL_CONTAINER,
  ORGANISER_SOCIAL_FACEBOOK,
  ORGANISER_SOCIAL_INSTAGRAM,
  ORGANISER_SOCIAL_LINKEDIN,
  ORGANISER_SOCIAL_PINTEREST,
  ORGANISER_SOCIAL_TIKTOK,
  ORGANISER_SOCIAL_X,
  ORGANISER_SOCIAL_YOUTUBE,
  PREVIEW_IFRAME_ID,
  REGISTRATION_CTA_ID,
  REGISTRATION_FORM_CTA_TEXT_ID,
  REGISTRATION_FORM_FIRST_NAME_ID,
  REGISTRATION_FORM_HEADING_TEXT_ID,
  REGISTRATION_FORM_ID,
  REGISTRATION_FORM_LAST_NAME_ID,
  REGISTRATION_FORM_PHONE_NUMBER_ID,
  STYLE_COLOR_ID,
  STYLE_DARK_TEXT_COLOR_ID,
  STYLE_LIGHT_BG_COLOR_ID,
  STYLE_LIGHTER_BG_COLOR_ID,
  TITLE_ID,
  VENUE_ICON_EMPTY_ID,
  VENUE_ICON_GOOGLE_MEET_ID,
  VENUE_ICON_MAP_ID,
  VENUE_ICON_ONLINE_ID,
  VENUE_ICON_ZOOM_ID,
  VENUE_MAP_ADDRESS_DIRECTIONS_ID,
  VENUE_MAP_ADDRESS_ID,
  VENUE_MAP_ID,
  VENUE_MAP_LOCATION_ID,
  VENUE_MAP_NAME_ID,
  VENUE_ONLINE_GOOGLE_MEET_ID,
  VENUE_ONLINE_ID,
  VENUE_ONLINE_ZOOM_ID,
  VENUE_SUMMARY_SUBTITLE,
  VENUE_SUMMARY_TITLE,
  WATERMARK_ID,
} from '@Components/event-wizard/preview/preview.types';
import {
  areDatesEqual,
  getDateMonthFormat,
  getReadableDayDateMonthFormatForDate,
  getReadableShortMonthForIndex,
  getReadableTimeForDate,
  getTimeDifferenceFromNowInMilliseconds,
  getTimeZoneOffsetFromUTCOffsetInMinutes,
} from '@Utils/date.util';
import type {EventWizardVenue} from '@Components/event-wizard/event-wizard-venue/event-wizard-venue.types';
import type {TimeZone} from 'timezones-list';
import type {PlaceDetails} from '@Components/google-map/google-map.types';
import type {OrganiserDetails} from '@Components/event-wizard/event-wizard-organisers/event-wizard-organisers.types';
import type {EventStyleColors} from '@Components/event-wizard/event-style/event-style.types';
import {getExtraShortWeekdaysString, loadFontFamilyInIframe} from '@Libraries/events.library';
import type {EventRegistrationFields} from '@Components/event-wizard/event-wizard-registrations/event-wizard-registrations.types';
import {CUSTOM_EVENT_GUEST_INFO_PHONE_TOGGLED} from '@Components/event-wizard/event-wizard-registrations/event-wizard-registrations.types';
import {isGoogleMeetLink, isZoomLink} from '@Utils/url.util';
import {getOrganiserProfilePicture} from '@Components/event-wizard/event-wizard-organisers/event-wizard-organisers.helper';
import {RecurringType} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import {ALL_WEEKDAYS} from '@Components/event-wizard/week-day-selectors/week-day-selectors.types';
import {getShortTzCodeWithUTCLabel, getShortTzCodeWithUTCLabelFallBack} from '@Components/event-wizard/timezone-content/timezone-content.helper';
import {getTranslatedWeekdays} from '@Components/event-wizard/event-wizard-date-time-summary/event-wizard-date-time-summary.types';
import {CUSTOM_EVENT_ORGANISER_WIZARD_SECTION_OPENED} from '@Components/event-wizard/event-wizard-review/event-wizard-review.types';

const WIZARD_DEFAULT_CLASS = '-wizard-default';

const getIframeDocument = (): Document | undefined => {
  const iframe = document.getElementById(PREVIEW_IFRAME_ID) as HTMLIFrameElement;

  if (!iframe) {
    return undefined;
  }
  return iframe.contentDocument ?? iframe.contentWindow?.document;
};

const getIframeDocumentWindow = (): WindowProxy | undefined => {
  const iframe = document.getElementById(PREVIEW_IFRAME_ID) as HTMLIFrameElement;

  if (!iframe) {
    return undefined;
  }
  return iframe.contentWindow ?? undefined;
};

export const updatePreviewTitle = (title: string): void => {
  const titleElements = getElementsByClass(TITLE_ID);

  if (!titleElements) {
    return;
  }
  let newTitle = title;

  for (let i = 0; i < titleElements.length; i++) {
    const titleElement = titleElements[i];

    if (title === '') {
      newTitle = titleElement.getAttribute('data-placeholder') ?? window.i18next.t('pmwjs_new_event');
      addWizardDefaultClass(titleElement);
    } else {
      removeWizardDefaultClass(titleElement);
    }
    titleElement.textContent = newTitle;
  }
};

const updateRegistrationTitle = (title: string, ctaTExt: string): void => {
  const registrationFormTitleEl = getElementByID(REGISTRATION_FORM_HEADING_TEXT_ID);
  if (registrationFormTitleEl) {
    registrationFormTitleEl.textContent = getHeadingForSignupForm(title, ctaTExt); // Replace with the desired text
  }
};

const getHeadingForSignupForm = (eventTitle: string, ctaText: string): string => {
  const ctaOptions: Record<string, string> = {
    Register: `🗓️ Register for ${eventTitle}`,
    Join: `🗓️ Join ${eventTitle}`,
    RSVP: `🗓️ RSVP for ${eventTitle}`,
    'Sign Up': `🗓️ Sign up for ${eventTitle}`,
    'Claim your spot': `🗓️ Claim your spot at ${eventTitle}`,
  };

  return ctaOptions[ctaText] || `🗓️ Sign up for ${eventTitle}`;
};

export const updatePreviewDescription = (description: string): void => {
  const descriptionElements = getElementsByClass(DESCRIPTION_ID);

  if (!descriptionElements) {
    return;
  }

  for (let i = 0; i < descriptionElements.length; i++) {
    const descriptionElement = descriptionElements[i];

    if (description === '') {
      addWizardDefaultClass(descriptionElement);
    } else {
      removeWizardDefaultClass(descriptionElement);
    }
    descriptionElement.innerHTML = description;
  }
};

export const updatePreviewVenue = (venue: EventWizardVenue): void => {
  updatePreviewSummaryVenueSection(venue);
  updatePreviewVenueSection(venue);
};

export const updatePreviewVenueAddressDetails = (address: string): void => {
  const addressEls = getElementsByClass(VENUE_MAP_ADDRESS_ID);

  if (!addressEls) {
    return;
  }

  for (let i = 0; i < addressEls.length; i++) {
    const addressEl = addressEls[i];
    addressEl.textContent = address;
  }
};

export const updatePreviewVenueAddressDirectionsDetails = (address: string): void => {
  const addressEls = getElementsByClass(VENUE_MAP_ADDRESS_DIRECTIONS_ID);

  if (!addressEls) {
    return;
  }

  for (let i = 0; i < addressEls.length; i++) {
    const addressEl = addressEls[i];
    addressEl.textContent = address;
  }
};

export const updatePreviewDateTime = (eventPreviewDateTime: EventPreviewDateTime): void => {
  eventPreviewDateTime.recurringPattern = window.PMW.redux.store.getState().eventWizard.recurringEvent;

  const getAllStateElements = (): Record<string, HTMLCollectionOf<Element> | null> => {
    return {
      [DATE_TIME_EMPTY_ID]: getElementsByClass(DATE_TIME_EMPTY_ID),
      [DATE_TIME_SINGLE_DAY_ID]: getElementsByClass(DATE_TIME_SINGLE_DAY_ID),
      [DATE_TIME_MULTIDAY_ID]: getElementsByClass(DATE_TIME_MULTIDAY_ID),
      [DATE_TIME_RECURRING_DAILY_ID]: getElementsByClass(DATE_TIME_RECURRING_DAILY_ID),
      [DATE_TIME_RECURRING_WEEKLY_SINGLE_ID]: getElementsByClass(DATE_TIME_RECURRING_WEEKLY_SINGLE_ID),
      [DATE_TIME_RECURRING_WEEKLY_MULTI_ID]: getElementsByClass(DATE_TIME_RECURRING_WEEKLY_MULTI_ID),
      [DATE_TIME_RECURRING_MONTHLY_ID]: getElementsByClass(DATE_TIME_RECURRING_MONTHLY_ID),
    };
  };

  const updateElementByState = (elements: HTMLCollectionOf<Element> | null): void => {
    if (!elements) {
      return;
    }
    const {startDateTime} = eventPreviewDateTime;
    const {endDateTime} = eventPreviewDateTime;

    displayElements(elements);

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      const monthIcon = getElementByClassFromElement(element, DATE_TIME_ICON_START_MONTH_ID);
      const dateIcon = getElementByClassFromElement(element, DATE_TIME_ICON_START_DATE_ID);
      const startTitle = getElementByClassFromElement(element, DATE_TIME_START_TITLE_ID);
      const startTitleMobile = getElementByClassFromElement(element, DATE_TIME_MOBILE_START_TITLE_ID);
      const startSubtitle = getElementByClassFromElement(element, DATE_TIME_START_SUBTITLE_ID);
      const endTitle = getElementByClassFromElement(element, DATE_TIME_END_TITLE_ID);
      const endTitleMobile = getElementByClassFromElement(element, DATE_TIME_MOBILE_END_TITLE_ID);
      const endSubtitle = getElementByClassFromElement(element, DATE_TIME_END_SUBTITLE_ID);

      let startTitleStr = null;
      let startTitleMobileStr = null;
      let startSubtitleStr = null;
      let endTitleStr = null;
      let endTitleMobileStr = null;
      let endSubtitleStr = null;

      if (monthIcon) {
        monthIcon.textContent = getReadableShortMonthForIndex(startDateTime.getMonth());
      }

      if (dateIcon) {
        dateIcon.textContent = startDateTime.getDate().toString();
      }

      if (isEmptyDateTime) {
        return;
      }
      const timezone = eventPreviewDateTime.timeZone;

      if (!isRecurring && isSameDate) {
        startTitleStr = startTitleMobileStr = getReadableDayDateMonthFormatForDate(startDateTime, true);
        endTitleStr = `${getReadableTimeForDate(startDateTime)} - ${getReadableTimeForDate(endDateTime)}`;
        endTitleMobileStr = `${getReadableTimeForDate(startDateTime)} - ${getReadableTimeForDate(endDateTime)} (${getShortTzCodeWithUTCLabelFallBack(timezone)})`;
        endSubtitleStr = getShortTzCodeWithUTCLabel(timezone);
      } else if (!isRecurring && !isSameDate) {
        startTitleStr = getReadableDayDateMonthFormatForDate(startDateTime, true);
        startTitleMobileStr = `${getDateMonthFormat(startDateTime)} · ${getReadableTimeForDate(startDateTime)}`;
        startSubtitleStr = `${getReadableTimeForDate(startDateTime)} (${getShortTzCodeWithUTCLabelFallBack(timezone)})`;
        endTitleStr = getReadableDayDateMonthFormatForDate(endDateTime, true);
        endTitleMobileStr = `${getDateMonthFormat(endDateTime)} · ${getReadableTimeForDate(endDateTime)} (${getShortTzCodeWithUTCLabelFallBack(timezone)})`;
        endSubtitleStr = `${getReadableTimeForDate(endDateTime)} (${getShortTzCodeWithUTCLabelFallBack(timezone)})`;
      } else if (isDailyRecurring) {
        startTitleStr = window.i18next.t('pmwjs_daily');
        endTitleStr = `${getReadableTimeForDate(startDateTime)} - ${getReadableTimeForDate(endDateTime)}`;
        endTitleMobileStr = `${endTitleStr} (${getShortTzCodeWithUTCLabelFallBack(timezone)})`;
        endSubtitleStr = getShortTzCodeWithUTCLabel(timezone);
      } else if (isWeeklySingleDayRecurring) {
        startTitleStr = startTitleMobileStr = getTranslatedWeekdays()[ALL_WEEKDAYS.indexOf(recurringPattern.recurringWeeks[0])];
        endTitleStr = `${getReadableTimeForDate(startDateTime)} - ${getReadableTimeForDate(endDateTime)}`;
        endTitleMobileStr = `${endTitleStr} (${getShortTzCodeWithUTCLabelFallBack(timezone)})`;
        endSubtitleStr = getShortTzCodeWithUTCLabel(timezone);
      } else if (isWeeklyMultiDayRecurring) {
        startTitleStr = window.i18next.t('pmwjs_weekly');
        startSubtitleStr = getExtraShortWeekdaysString(recurringPattern);
        startTitleMobileStr = `${startTitleStr} · ${startSubtitleStr}`;
        endTitleStr = `${getReadableTimeForDate(startDateTime)} - ${getReadableTimeForDate(endDateTime)}`;
        endTitleMobileStr = `${endTitleStr} (${getShortTzCodeWithUTCLabelFallBack(timezone)})`;
        endSubtitleStr = getShortTzCodeWithUTCLabel(timezone);
      } else if (isMonthlyRecurring) {
        startTitleStr = window.i18next.t('pmwjs_monthly');
        startSubtitleStr = recurringPattern.recurringMonthly.weekdayNum + ' ' + getTranslatedWeekdays()[ALL_WEEKDAYS.indexOf(recurringPattern.recurringMonthly.weekday)];
        startTitleMobileStr = `${startTitleStr} · ${startSubtitleStr}`;
        endTitleStr = `${getReadableTimeForDate(startDateTime)} - ${getReadableTimeForDate(endDateTime)}`;
        endTitleMobileStr = `${endTitleStr} (${getShortTzCodeWithUTCLabelFallBack(timezone)})`;
        endSubtitleStr = getShortTzCodeWithUTCLabel(timezone);
      }

      if (startTitle && startTitleStr) {
        startTitle.textContent = startTitleStr;
      }

      if (startTitleMobile && startTitleMobileStr) {
        startTitleMobile.textContent = startTitleMobileStr;
      }

      if (startSubtitle && startSubtitleStr) {
        startSubtitle.textContent = startSubtitleStr;
      }

      if (endTitle && endTitleStr) {
        endTitle.textContent = endTitleStr;
      }

      if (endTitleMobile && endTitleMobileStr) {
        endTitleMobile.textContent = endTitleMobileStr;
      }

      if (endSubtitle && endSubtitleStr) {
        endSubtitle.textContent = endSubtitleStr;
      }
    }
  };

  const {startDateTime, endDateTime} = eventPreviewDateTime;
  const dateTimeStateElements = getAllStateElements();
  let elementStateToUpdate: HTMLCollectionOf<Element> | null = null;

  hideElementsInDict(dateTimeStateElements);

  const isEmptyDateTime = !startDateTime || !endDateTime;
  const isSameDate = areDatesEqual(startDateTime, endDateTime);
  const isRecurring = eventPreviewDateTime.recurringPattern?.isRecurring;
  const recurringPattern = eventPreviewDateTime.recurringPattern;
  const isDailyRecurring = recurringPattern?.recurringType === RecurringType.DAILY;
  const isWeeklySingleDayRecurring = recurringPattern?.recurringType === RecurringType.WEEKLY && recurringPattern.recurringWeeks?.length === 1;
  const isWeeklyMultiDayRecurring = recurringPattern?.recurringType === RecurringType.WEEKLY && recurringPattern.recurringWeeks?.length > 1;
  const isMonthlyRecurring = recurringPattern?.recurringType === RecurringType.MONTHLY && recurringPattern.recurringMonthly;

  if (isEmptyDateTime) {
    elementStateToUpdate = dateTimeStateElements[DATE_TIME_EMPTY_ID];
  } else if (!isRecurring && isSameDate) {
    elementStateToUpdate = dateTimeStateElements[DATE_TIME_SINGLE_DAY_ID];
  } else if (!isRecurring && !isSameDate) {
    elementStateToUpdate = dateTimeStateElements[DATE_TIME_MULTIDAY_ID];
  } else if (isDailyRecurring) {
    elementStateToUpdate = dateTimeStateElements[DATE_TIME_RECURRING_DAILY_ID];
  } else if (isWeeklySingleDayRecurring) {
    elementStateToUpdate = dateTimeStateElements[DATE_TIME_RECURRING_WEEKLY_SINGLE_ID];
  } else if (isWeeklyMultiDayRecurring) {
    elementStateToUpdate = dateTimeStateElements[DATE_TIME_RECURRING_WEEKLY_MULTI_ID];
  } else if (isMonthlyRecurring) {
    elementStateToUpdate = dateTimeStateElements[DATE_TIME_RECURRING_MONTHLY_ID];
  } else {
    console.log('unable to update preview for unknowncase'); // selfCodeReviewJibran: error
  }
  updateElementByState(elementStateToUpdate);
};

export const updatePreviewTimeZone = (timeZone: string): void => {
  const timeZoneEls = getElementsByClass(DATE_TIME_TIMEZONE_ID);

  if (!timeZoneEls || timeZoneEls.length === 0) {
    return;
  }

  for (let i = 0; i < timeZoneEls.length; i++) {
    const timeZoneEl = timeZoneEls[i];
    timeZoneEl.textContent = timeZone;
  }
};

export const updatePreviewCountdown = (endDateTime: Date | undefined, timeZone: TimeZone): void => {
  const updateTimeLeft = (elementContainer: Element | null, time?: number): void => {
    if (!elementContainer) {
      return;
    }

    if (time === undefined) {
      hideElement(elementContainer);
      return;
    }
    displayElement(elementContainer);
    const elementTime = getElementByClassFromElement(elementContainer, COUNTDOWN_TIME_ID);

    if (elementTime) {
      let timeStr = time.toString();

      if (time < 10) {
        timeStr = `0${timeStr}`;
      }
      elementTime.textContent = timeStr;
    }
  };

  const countdownElements = getElementsByClass(COUNTDOWN_ID);

  if (!countdownElements) {
    return;
  }

  if (!endDateTime) {
    hideElementsInCollection(countdownElements);
    return;
  }
  displayElements(countdownElements);

  const diffInMillis = getTimeDifferenceFromNowInMilliseconds(endDateTime, getTimeZoneOffsetFromUTCOffsetInMinutes(timeZone.utc));

  const daysLeft = Math.floor(diffInMillis / (1000 * 60 * 60 * 24));
  const hoursLeft = Math.floor((diffInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutesLeft = Math.floor((diffInMillis % (1000 * 60 * 60)) / (1000 * 60));
  const secondsLeft = Math.floor((diffInMillis % (1000 * 60)) / 1000);

  for (let i = 0; i < countdownElements.length; i++) {
    const countdownElement = countdownElements[i];

    const daysEl = getElementByClassFromElement(countdownElement, COUNTDOWN_DAYS_CONTAINER_ID);
    const hoursEl = getElementByClassFromElement(countdownElement, COUNTDOWN_HOURS_CONTAINER_ID);
    const minsEl = getElementByClassFromElement(countdownElement, COUNTDOWN_MINS_CONTAINER_ID);
    const secsEl = getElementByClassFromElement(countdownElement, COUNTDOWN_SECS_CONTAINER_ID);

    updateTimeLeft(daysEl, daysLeft === 0 ? undefined : daysLeft);
    updateTimeLeft(hoursEl, hoursLeft + daysLeft === 0 ? undefined : hoursLeft);
    updateTimeLeft(minsEl, daysLeft + hoursLeft + minutesLeft === 0 ? undefined : minutesLeft);
    updateTimeLeft(secsEl, secondsLeft);
  }
  getIframeDocumentWindow()?.dispatchEvent(new CustomEvent(CUSTOM_EVENT_COUNTDOWN_WIZARD_UPDATED));
};

export const toggleDisplayPreviewCoverPhotoSection = (shouldDisplay: boolean = true): void => {
  const els = getElementsByClass(COVER_PHOTO_CONTAINER_ID);

  if (shouldDisplay) {
    displayElements(els);
  } else {
    hideElementsInCollection(els);
  }
};

export const togglePreviewCoverPhotoLoading = (isLoading: boolean): void => {
  const coverPhotoContainerEls: HTMLCollectionOf<Element> | null = getElementsByClass(COVER_PHOTO_CONTAINER_ID);

  if (coverPhotoContainerEls && coverPhotoContainerEls.length > 0) {
    for (let i = 0; i < coverPhotoContainerEls.length; i++) {
      const coverPhotoContainerEl = coverPhotoContainerEls[i];

      if (isLoading) {
        coverPhotoContainerEl.classList.add('-loading');
      } else {
        coverPhotoContainerEl.classList.remove('-loading');
      }
    }
  }
};

export const updatePreviewCoverPhoto = async (url: string | undefined): Promise<void> => {
  async function isLandscape(imageUrl: string): Promise<boolean> {
    try {
      const response = await fetch(imageUrl);

      if (!response.ok) {
        return false;
      }
      const blob = await response.blob();
      const img = new Image();

      return await new Promise((resolve, reject) => {
        img.src = URL.createObjectURL(blob);
        img.onload = (): void => {
          resolve(img.width > img.height);
        };
        img.onerror = (): void => {
          reject(new Error('Failed to load image'));
        };
      });
    } catch (e) {
      return false;
    }
  }

  const coverPhotoContainerEls = getElementsByClass(COVER_PHOTO_CONTAINER_ID);
  const coverPhotoEls = getElementsByClass(COVER_PHOTO_ID);
  const coverPhotoBgEls = getElementsByClass(COVER_PHOTO_BACKGROUND_ID);
  const coverPhotoPlaceholderEls = getElementsByClass(COVER_PHOTO_PLACEHOLDER_ICON_ID);

  toggleDisplayPreviewCoverPhotoSection(true);

  if (coverPhotoContainerEls && coverPhotoContainerEls.length > 0) {
    for (let i = 0; i < coverPhotoContainerEls.length; i++) {
      const coverPhotoContainerEl = coverPhotoContainerEls[i];

      if (url && url !== '') {
        displayElement(coverPhotoContainerEl);
        void isLandscape(url).then((isLandscape) => {
          if (isLandscape) {
            coverPhotoContainerEl.classList.add('-landscape');
          } else {
            coverPhotoContainerEl.classList.remove('-landscape');
          }
        });
      }
    }
  }

  if (coverPhotoEls && coverPhotoEls.length > 0) {
    for (let i = 0; i < coverPhotoEls.length; i++) {
      const coverPhotoEl = coverPhotoEls[i];

      if (!url || url === '') {
        hideElement(coverPhotoEl);
      } else {
        coverPhotoEl.setAttribute('src', url);
        displayElement(coverPhotoEl);
      }
    }
  }

  if (coverPhotoBgEls && coverPhotoBgEls.length > 0) {
    for (let i = 0; i < coverPhotoBgEls.length; i++) {
      const coverPhotoBgEl = coverPhotoBgEls[i] as HTMLElement;

      if (!url || url === '') {
        coverPhotoBgEl.style.background = `linear-gradient(180deg, rgba(255, 255, 255, 0.60) 12.03%, #FFF 89.17%), url('') lightgray 50% / cover no-repeat`;
      } else {
        coverPhotoBgEl.style.background = `linear-gradient(180deg, rgba(255, 255, 255, 0.60) 12.03%, #FFF 89.17%), url('${url}') lightgray 50% / cover no-repeat`;
        displayElement(coverPhotoBgEl);
      }
    }
  }

  if (coverPhotoPlaceholderEls && coverPhotoPlaceholderEls.length > 0) {
    for (let i = 0; i < coverPhotoPlaceholderEls.length; i++) {
      const coverPhotoPlaceholderEl = coverPhotoPlaceholderEls[i];

      if (!url || url === '') {
        displayElement(coverPhotoPlaceholderEl);
      } else {
        hideElement(coverPhotoPlaceholderEl);
      }
    }
  }
  togglePreviewCoverPhotoLoading(false);
};

export const updatePreviewOrganiserVisibilityToShow = (): void => {
  getIframeDocumentWindow()?.dispatchEvent(new CustomEvent(CUSTOM_EVENT_ORGANISER_WIZARD_SECTION_OPENED));
};

export const updatePreviewOrganiserDetails = (organiserDetails: OrganiserDetails): void => {
  const organiserProfilePictureEls = getElementsByClass(ORGANISER_PROFILE_PICTURE);
  const organiserNameEls = getElementsByClass(ORGANISER_NAME);
  const organiserEmailEls = getElementsByClass(ORGANISER_EMAIL);
  const organiserPhoneEls = getElementsByClass(ORGANISER_PHONE);

  if (organiserProfilePictureEls) {
    for (let i = 0; i < organiserProfilePictureEls.length; i++) {
      const organiserProfilePictureEl = organiserProfilePictureEls[i];
      organiserProfilePictureEl.setAttribute('src', getOrganiserProfilePicture(organiserDetails));
    }
  }

  if (organiserNameEls) {
    for (let i = 0; i < organiserNameEls.length; i++) {
      const organiserNameEl = organiserNameEls[i];
      organiserNameEl.textContent = organiserDetails.name ? organiserDetails.name : window.i18next.t('pmwjs_name');
    }
  }

  if (organiserEmailEls) {
    for (let i = 0; i < organiserEmailEls.length; i++) {
      const organiserEmailEl = organiserEmailEls[i];
      organiserEmailEl.textContent = organiserDetails.email ? organiserDetails.email : window.i18next.t('pmwjs_email');
    }
  }

  if (organiserPhoneEls) {
    for (let i = 0; i < organiserPhoneEls.length; i++) {
      const organiserPhoneEl = organiserPhoneEls[i];
      organiserPhoneEl.textContent = organiserDetails.phone ? organiserDetails.phone : window.i18next.t('pmwjs_phone');
    }
  }
  updateOrganiserSocialDetails(organiserDetails);
};

const updateOrganiserSocialDetails = (organiserDetails: OrganiserDetails): void => {
  const {socialMediaLinks} = organiserDetails;
  const organiserSocialEls = getElementsByClass(ORGANISER_SOCIAL_CONTAINER);
  const organiserFacebookEls = getElementsByClass(ORGANISER_SOCIAL_FACEBOOK);
  const organiserInstagramEls = getElementsByClass(ORGANISER_SOCIAL_INSTAGRAM);
  const organiserLinkedinEls = getElementsByClass(ORGANISER_SOCIAL_LINKEDIN);
  const organiserXEls = getElementsByClass(ORGANISER_SOCIAL_X);
  const organiserPinterestEls = getElementsByClass(ORGANISER_SOCIAL_PINTEREST);
  const organiserYoutubeEls = getElementsByClass(ORGANISER_SOCIAL_YOUTUBE);
  const organiserTiktokEls = getElementsByClass(ORGANISER_SOCIAL_TIKTOK);

  const updateSocialMediaEls = (link: string, els: HTMLCollectionOf<Element> | null): void => {
    if (!els) {
      return;
    }

    if (link) {
      displayElements(els);
    } else {
      hideElementsInCollection(els);
    }
  };
  updateSocialMediaEls(socialMediaLinks.facebook, organiserFacebookEls);
  updateSocialMediaEls(socialMediaLinks.instagram, organiserInstagramEls);
  updateSocialMediaEls(socialMediaLinks.linkedin, organiserLinkedinEls);
  updateSocialMediaEls(socialMediaLinks.x, organiserXEls);
  updateSocialMediaEls(socialMediaLinks.pinterest, organiserPinterestEls);
  updateSocialMediaEls(socialMediaLinks['youtube-channel'], organiserYoutubeEls);
  updateSocialMediaEls(socialMediaLinks['tiktok-profile'], organiserTiktokEls);

  if (
    socialMediaLinks.facebook ||
    socialMediaLinks.instagram ||
    socialMediaLinks.linkedin ||
    socialMediaLinks.x ||
    socialMediaLinks.pinterest ||
    socialMediaLinks['youtube-channel'] ||
    socialMediaLinks['tiktok-profile']
  ) {
    displayElements(organiserSocialEls);
  } else {
    hideElementsInCollection(organiserSocialEls);
  }
};

export const toggleDisplayPreviewRegistrationForm = (shouldDisplay: boolean): void => {
  const registrationFormEl = getElementByID(REGISTRATION_FORM_ID);

  if (!registrationFormEl) {
    return;
  }

  if (shouldDisplay) {
    displayElement(registrationFormEl);
    const signupContainer = getElementByClassFromElement(registrationFormEl, 'js-email-signup-form-container') as HTMLElement;
    if (signupContainer) {
      signupContainer.style.display = 'flex';
    }
  } else {
    hideElement(registrationFormEl);
  }
};

export const updatePreviewGuestInfoPhoneVisibility = (isToggled: boolean): void => {
  if (isToggled) {
    getIframeDocumentWindow()?.dispatchEvent(new CustomEvent(CUSTOM_EVENT_GUEST_INFO_PHONE_TOGGLED));
  }
};

export const updatePreviewRegistrationCTAText = (ctaText: string): void => {
  const registrationCTAs = getElementsByClass(REGISTRATION_CTA_ID);

  if (!registrationCTAs || registrationCTAs.length === 0) {
    return;
  }

  for (let i = 0; i < registrationCTAs.length; i++) {
    const registrationCTA = registrationCTAs[i];
    registrationCTA.textContent = ctaText;
  }
};

export const updatePreviewRegistrationForm = (fields: EventRegistrationFields, title: string): void => {
  const firstNameFieldEl = getElementByID(REGISTRATION_FORM_FIRST_NAME_ID);
  const lastNameFieldEl = getElementByID(REGISTRATION_FORM_LAST_NAME_ID);
  const phoneNumberFieldEl = getElementByID(REGISTRATION_FORM_PHONE_NUMBER_ID);
  const ctaElement = getElementByID(REGISTRATION_FORM_CTA_TEXT_ID);

  if (fields.name) {
    displayElement(firstNameFieldEl);
    displayElement(lastNameFieldEl);
  } else {
    hideElement(firstNameFieldEl);
    hideElement(lastNameFieldEl);
  }

  if (fields.phone) {
    displayElement(phoneNumberFieldEl);
  } else {
    hideElement(phoneNumberFieldEl);
  }

  if (fields.registerBtnText) {
    if (ctaElement) {
      const registerButton = ctaElement.querySelector("input[type='submit']") as HTMLInputElement;
      if (registerButton) {
        registerButton.value = fields.registerBtnText;
      }
    }

    const titleElements = getElementsByClass(TITLE_ID);
    if (titleElements) {
      const titleElement = titleElements[0];
      if (titleElement?.textContent) {
        updateRegistrationTitle(title, fields.registerBtnText);
      }
    }
  }
};

export const updatePreviewWatermark = (isRemoveWatermark: boolean): void => {
  const watermarkFieldEls = getElementsByClass(WATERMARK_ID);

  if (isRemoveWatermark) {
    hideElementsInCollection(watermarkFieldEls);
  } else {
    displayElements(watermarkFieldEls);
  }
};

export const updateStyleColor = (colors: EventStyleColors): void => {
  const lighterBgEls = getElementsByClass(STYLE_LIGHTER_BG_COLOR_ID);
  const lightBgEls = getElementsByClass(STYLE_LIGHT_BG_COLOR_ID);
  const darkTextEls = getElementsByClass(STYLE_DARK_TEXT_COLOR_ID);
  const selectedStyleEls = getElementsByClass(STYLE_COLOR_ID);
  const ctaElement = getElementByID(REGISTRATION_FORM_CTA_TEXT_ID);

  if (lighterBgEls) {
    for (let i = 0; i < lighterBgEls.length; i++) {
      const lighterBgEl = lighterBgEls[i] as HTMLElement;
      lighterBgEl.style.background = colors.lighter;
    }
  }

  if (lightBgEls) {
    for (let i = 0; i < lightBgEls.length; i++) {
      const lightBgEl = lightBgEls[i] as HTMLElement;
      lightBgEl.style.background = colors.light;
    }
  }

  if (darkTextEls) {
    for (let i = 0; i < darkTextEls.length; i++) {
      const darkTextEl = darkTextEls[i] as HTMLElement;
      darkTextEl.style.color = colors.dark;
    }
  }

  if (selectedStyleEls) {
    for (let i = 0; i < selectedStyleEls.length; i++) {
      const selectedStyleEl = selectedStyleEls[i] as HTMLElement;
      selectedStyleEl.style.background = colors.primary;
    }
  }

  if (ctaElement) {
    const registerButton = ctaElement.querySelector("input[type='submit']") as HTMLElement;
    if (registerButton) {
      registerButton.style.setProperty('background-color', colors.primary);
    }
  }
};

export const updateStyleFont = (font: string): void => {
  const iframeDoc = getIframeDocument();

  if (iframeDoc) {
    const titleEls = getElementsByClass(TITLE_ID);

    if (!titleEls) {
      return;
    }

    for (let i = 0; i < titleEls?.length; i++) {
      const titleEl = titleEls[i];
      titleEl.classList.add('-loading');
    }
    loadFontFamilyInIframe(iframeDoc, font);
  }
};

export const togglePreviewTitleLoading = (isLoading: boolean): void => {
  const titleEls = getElementsByClass(TITLE_ID);

  if (!titleEls) {
    return;
  }

  for (let i = 0; i < titleEls?.length; i++) {
    const titleEl = titleEls[i];

    if (isLoading) {
      titleEl.classList.add('-loading');
    } else {
      titleEl.classList.remove('-loading');
    }
  }
};

const updatePreviewSummaryVenueSection = (venue: EventWizardVenue): void => {
  const getAllStateElements = (): {[p: string]: HTMLCollectionOf<Element> | null} => {
    return {
      [VENUE_ICON_EMPTY_ID]: getElementsByClass(VENUE_ICON_EMPTY_ID),
      [VENUE_ICON_MAP_ID]: getElementsByClass(VENUE_ICON_MAP_ID),
      [VENUE_ICON_GOOGLE_MEET_ID]: getElementsByClass(VENUE_ICON_GOOGLE_MEET_ID),
      [VENUE_ICON_ZOOM_ID]: getElementsByClass(VENUE_ICON_ZOOM_ID),
      [VENUE_ICON_ONLINE_ID]: getElementsByClass(VENUE_ICON_ONLINE_ID),
    };
  };

  const updateOfflineDetails = (elements: HTMLCollectionOf<Element> | null, offlineVenueDetails: PlaceDetails): void => {
    if (!elements) {
      return;
    }

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const titleElement = getElementByClassFromElement(element, VENUE_SUMMARY_TITLE);
      const subtitleElement = getElementByClassFromElement(element, VENUE_SUMMARY_SUBTITLE);

      if (titleElement) {
        titleElement.textContent = offlineVenueDetails.name ?? null;
      }

      if (subtitleElement) {
        subtitleElement.textContent = offlineVenueDetails.address ?? null;
      }
    }
  };

  const venueSummaryStateElements = getAllStateElements();

  hideElementsInDict(venueSummaryStateElements);

  if (venue.offlineVenue) {
    displayElements(venueSummaryStateElements[VENUE_ICON_MAP_ID]);
    updateOfflineDetails(venueSummaryStateElements[VENUE_ICON_MAP_ID], venue.offlineVenue);
  } else if (venue.onlineVenueURL) {
    let onlineStateID = VENUE_ICON_ONLINE_ID;

    if (isGoogleMeetLink(venue.onlineVenueURL)) {
      onlineStateID = VENUE_ICON_GOOGLE_MEET_ID;
    } else if (isZoomLink(venue.onlineVenueURL)) {
      onlineStateID = VENUE_ICON_ZOOM_ID;
    }
    displayElements(venueSummaryStateElements[onlineStateID]);
  } else {
    displayElements(venueSummaryStateElements[VENUE_ICON_EMPTY_ID]);
  }
};

const updatePreviewVenueSection = (venue: EventWizardVenue): void => {
  const getAllStateElements = (): {[p: string]: HTMLCollectionOf<Element> | null} => {
    return {
      [VENUE_MAP_ID]: getElementsByClass(VENUE_MAP_ID),
      [VENUE_ONLINE_ID]: getElementsByClass(VENUE_ONLINE_ID),
      [VENUE_ONLINE_GOOGLE_MEET_ID]: getElementsByClass(VENUE_ONLINE_GOOGLE_MEET_ID),
      [VENUE_ONLINE_ZOOM_ID]: getElementsByClass(VENUE_ONLINE_ZOOM_ID),
    };
  };

  const updateOfflineDetails = (elements: HTMLCollectionOf<Element> | null, offlineVenueDetails: PlaceDetails): void => {
    if (!elements) {
      return;
    }

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const titleElement = getElementByClassFromElement(element, VENUE_MAP_NAME_ID);
      const subtitleElement = getElementByClassFromElement(element, VENUE_MAP_ADDRESS_ID);
      const venueMapLocationElement = getElementByClassFromElement(element, VENUE_MAP_LOCATION_ID);

      if (titleElement) {
        titleElement.textContent = offlineVenueDetails.name ?? null;
      }

      if (subtitleElement) {
        subtitleElement.textContent = offlineVenueDetails.address ?? null;
      }

      if (venueMapLocationElement) {
        const lng = offlineVenueDetails.longitude.toString();
        const lat = offlineVenueDetails.latitude.toString();
        venueMapLocationElement.setAttribute('data-lng', lng ?? '');
        venueMapLocationElement.setAttribute('data-lat', lat ?? '');
      }
    }
  };

  const venueStateElements = getAllStateElements();

  hideElementsInDict(venueStateElements);

  if (venue.offlineVenue) {
    displayElements(venueStateElements[VENUE_MAP_ID]);
    updateOfflineDetails(venueStateElements[VENUE_MAP_ID], venue.offlineVenue);
  } else if (venue.onlineVenueURL) {
    let onlineStateID = VENUE_ONLINE_ID;

    if (isGoogleMeetLink(venue.onlineVenueURL)) {
      onlineStateID = VENUE_ONLINE_GOOGLE_MEET_ID;
    } else if (isZoomLink(venue.onlineVenueURL)) {
      onlineStateID = VENUE_ONLINE_ZOOM_ID;
    }
    displayElements(venueStateElements[onlineStateID]);
  }
};

const getElementsByClass = (className: string): HTMLCollectionOf<Element> | null => {
  const iframeDocument = getIframeDocument();

  if (!iframeDocument) {
    return null;
  }
  const elements = iframeDocument.getElementsByClassName(className);

  if (!elements || elements.length === 0) {
    return null;
  }
  return elements;
};

const getElementByID = (id: string): Element | null => {
  const iframeDocument = getIframeDocument();

  if (!iframeDocument) {
    return null;
  }
  const element = iframeDocument.getElementById(id);

  if (!element) {
    return null;
  }
  return element;
};

const getElementByClassFromElement = (element: Element, className: string): Element | null => {
  const elements = element.getElementsByClassName(className);

  if (elements && elements.length > 0) {
    return elements[0];
  }
  return null;
};

const hideElementsInDict = (elementsDict: {[p: string]: HTMLCollectionOf<Element> | null}): void => {
  Object.keys(elementsDict).forEach((key) => {
    const elements = elementsDict[key];
    hideElementsInCollection(elements);
  });
};

const hideElementsInCollection = (elements: HTMLCollectionOf<Element> | null): void => {
  if (elements) {
    Array.from(elements).forEach((elementToClassUpdate) => {
      hideElement(elementToClassUpdate);
    });
  }
};

const hideElement = (element: Element | null): void => {
  if (element) {
    element.classList.add('_hidden');
  }
};

const displayElements = (elements: HTMLCollectionOf<Element> | null): void => {
  if (!elements) {
    return;
  }

  for (let i = 0; i < elements.length; i++) {
    displayElement(elements[i]);
  }
};

const displayElement = (element: Element | null): void => {
  if (!element) {
    return;
  }
  element.classList.remove('_hidden');
};

const addWizardDefaultClass = (element: Element): void => {
  element.classList.add(WIZARD_DEFAULT_CLASS);
};

const removeWizardDefaultClass = (element: Element): void => {
  element.classList.remove(WIZARD_DEFAULT_CLASS);
};
