import {isUserPremiumPlus} from '@Libraries/user.library';
import {isPAYGUser} from "@Utils/user.util";

export interface EventWizardOtherOptionsProps {
  // add props here
}

export const shouldUpsellUserForRemoveWatermark = (): boolean => {
  return !isUserPremiumPlus();
};

export const shouldUpsellUserForTeamShare = (): boolean => {
  return isPAYGUser();
}