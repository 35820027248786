import type {TextItemObject} from '@PosterWhiteboard/items/text-item/text-item.types';

export interface EventWizardImageVideoProps {
  // add props here
}

export interface CoverPhoto {
  uploadedUrl?: string;
  mediaType?: MediaTypes;
  selectedTemplateHID?: string;
  isPreviewLoading?: boolean;
}

export enum MediaTypes {
  TEMPLATES = 0,
  MY_DESIGNS = 1,
  UPLOAD = 2,
}

export interface EventsPrefillMorphData {
  eventName: string;
  eventTimings: string;
  eventDuration: string;
  eventLocation: string;
  eventDates: string;
  eventType: string;
}

export type EventsPreFillTextChanges = Partial<TextItemObject>;

export interface EventsPrefillMorphResponse {
  textContentChanges: Record<string, EventsPreFillTextChanges>;
}
