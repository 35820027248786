import type {RecurringEvent} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import type {EventWizardVenue} from '@Components/event-wizard/event-wizard-venue/event-wizard-venue.types';
import type {EventType, EventTypes} from '@Components/event-wizard/type-dropdown/type-dropdown.types';
import type {TimeZone} from 'timezones-list';
import type {EventRegistrationFields} from '@Components/event-wizard/event-wizard-registrations/event-wizard-registrations.types';
import type {CoverPhoto} from '@Components/event-wizard/event-wizard-image-video/event-wizard-image-video.types';
import type {EventStyles} from '@Components/event-wizard/event-style/event-style.types';
import type {EventWizardOrganisers, OrganiserDetails} from '@Components/event-wizard/event-wizard-organisers/event-wizard-organisers.types';
import type {PlaceDetails} from '@Components/google-map/google-map.types';
import type {EventVO, EventVOMinifiedWizardPrefill} from '@Components/events/events.types';
import type {DateTimeMobilePanel} from '@Panels/event-wizard-date-time-panel/event-wizard-date-time-panel.types';

export interface EventWizardProps {
  googleMapAPIKey: string;
  organisers: OrganiserDetails[];
  types: EventType[];
  teamName: string | null;
  userPastVenues: PlaceDetails[];
  eventWizardSourceType: EventWizardSourceType;
  eventVO?: EventVO;
  eventVOMinifiedWizardPrefill?: EventVOMinifiedWizardPrefill;
}

export enum EventWizardSourceType {
  DEFAULT = 0,
  DUPLICATE = 1,
  UPDATE = 2,
  PREFILL_FROM_DESIGN = 3,
  PREFILL_FROM_DATE_SELECTION = 4,
}

export interface EventWizardReduxState {
  eventHID?: string;
  googleMapAPIKey: string;
  title: string;
  types: EventTypes;
  description: string;
  startDateTime: Date; // This is in the exact format the user has entered the date/time in. It is not converted to any timezone. Refer to PHP function: convertTimeToUTC
  endDateTime: Date; // This is in the exact format the user has entered the date/time in. It is not converted to any timezone. Refer to PHP function: convertTimeToUTC
  timeZone: TimeZone;
  recurringEvent: RecurringEvent;
  showCountdown: boolean;
  venue: EventWizardVenue;
  userPastVenues: PlaceDetails[];
  registrationDetails: EventRegistrationFields;
  isRemoveWatermark: boolean;
  isSharedInTeam: boolean;
  isSharedAsGoogleEvent: boolean;
  organisers: EventWizardOrganisers;
  coverPhoto: CoverPhoto;
  shouldCheckIfInErrState: boolean;
  styles: EventStyles;
  teamName?: string;
  currentProgressStep: EventWizardProgressStep;
  isEventBeingCreated: boolean;
  shouldNextBtnBeDisabled: boolean;
  shouldUpdateExpandedReviewSection: boolean;
  dateTimeMobilePanel: DateTimeMobilePanel;
  pageSourceType: EventWizardSourceType;
  isDesignPrefillContentLoading: boolean;
  progressStepLandedOn: EventWizardProgressStep; // When landing on the wizard, we can land on a specific progress step
}

export interface EventWizardContainerQuery {
  isExtraSmallScreen?: boolean;
  isSmallScreen?: boolean;
  isMediumScreen?: boolean;
  isLargeScreen?: boolean;
  isExtraLargeScreen?: boolean;
}

export enum EventWizardProgressStep {
  OVERVIEW = 'OVERVIEW',
  REVIEW = 'REVIEW',
}

export enum EventCreationErrorType {
  NUM_CREATION_LIMIT = 'creation_limit',
  UNKNOWN = 'unknown',
}

export const LOADING_TOAST_KEY = 'WizardLoading';
export const MESSAGE_GROWL_KEY = 'MessageGrowl';

export const orderedProgressSteps = [EventWizardProgressStep.OVERVIEW, EventWizardProgressStep.REVIEW];
