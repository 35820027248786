import {getUserPremiumLevel, isUserLoggedIn} from '@Libraries/user.library';
import {USER_PREMIUM_LEVELS} from '@Utils/user.util';

export interface EventWizardRegistrationsProps {
  // add props here
}

export interface EventRegistrationFields {
  email: boolean;
  name: boolean;
  phone: boolean;
  registerBtnText: string;
}

export const CUSTOM_EVENT_GUEST_INFO_PHONE_TOGGLED = 'guest_info_phone_toggle_wizard';

export const getRegistrationButtonTextOptions = (): string[] => {
  return [
    window.i18next.t('pmwjs_register'),
    window.i18next.t('pmwjs_sign_up'),
    window.i18next.t('pmwjs_rsvp'),
    window.i18next.t('pmwjs_join'),
    window.i18next.t('pmwjs_claim_spot'),
  ];
};

export const registerTextDropdownID = 'RegisterTextDropdownID';

export const shouldUpsellUserForNumRegistrations = (): boolean => {
  if (!isUserLoggedIn()) {
    return false;
  }
  return getUserPremiumLevel() === USER_PREMIUM_LEVELS.PAYG;
};

export const shouldUpsellUserForRegistrationOptions = (): boolean => {
  return getUserPremiumLevel() === USER_PREMIUM_LEVELS.PAYG;
};
