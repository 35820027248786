import type {Weekdays} from '@Components/event-wizard/week-day-selectors/week-day-selectors.types';
import type {RecurringMonth} from '@Components/event-wizard/month-day-selectors/month-day-selectors.types';

export interface RecurringEventSelectorProps {
  endDate: Date;
  isMobile?: boolean;
  onToggleRecurring?: (isEnabled: boolean) => void;
}

export interface RecurringEvent {
  isRecurring: boolean;
  recurringType: RecurringType;
  recurringEndDate: Date;
  recurringEndDateStr?: string;
  recurringWeeks: Weekdays[];
  recurringMonthly: RecurringMonth;
}

export enum RecurringType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export const ALL_RECURRING_TYPES = [RecurringType.DAILY, RecurringType.WEEKLY, RecurringType.MONTHLY];
export const recurringEventTypeDropdownID = 'recurringEventTypeDropdownID';
