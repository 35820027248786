export interface EventWizardReviewProps {
  // add props here
}

export enum SectionCompleteType {
  DONE = 0,
  PENDING = 1,
  OPTIONAL = 2,
}

export enum SectionType {
  BASIC = 0,
  MEDIA = 1,
  ORGANISER = 2,
  REGISTRATION = 3,
  OTHERS = 4,
}

export const CUSTOM_EVENT_ORGANISER_WIZARD_SECTION_OPENED = 'order_section_opened_wizard';
