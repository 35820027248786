import type {PlaceDetails} from '@Components/google-map/google-map.types';
import {doesTextContainGoogleMeetURL, extractGoogleMeetURL, isValidURL, prependProtocol} from '@Utils/url.util';

export interface EventWizardVenueProps {
  // add props here
}

export interface EventWizardVenue {
  selectedVenueType?: EventVenueType;
  onlineVenueURL?: string;
  offlineVenue?: PlaceDetails;
}

export enum EventVenueType {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export const isEventVenueComplete = (venue: EventWizardVenue): boolean => {
  if (venue.selectedVenueType === EventVenueType.OFFLINE && isOfflineVenueComplete(venue.offlineVenue)) {
    return true;
  }
  return venue.selectedVenueType === EventVenueType.ONLINE && getIsOnlineVenueComplete(venue.onlineVenueURL);
};

export const isOfflineVenueComplete = (venue: PlaceDetails | undefined): boolean => {
  return !!(venue?.name?.trim() && venue.address?.trim());
};

export const getIsOnlineVenueComplete = (url: string | undefined): boolean => {
  return !!url && isValidURL(url);
};

export const cleanUserInputURL = (url: string | undefined): string | undefined => {
  if (!url) {
    return undefined;
  }

  if (doesTextContainGoogleMeetURL(url)) {
    return extractGoogleMeetURL(url);
  }
  return prependProtocol(url);
};
