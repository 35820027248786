import {DateTimeErrorType, MAX_EVENT_LENGTH_DAYS} from '@Components/event-wizard/event-wizard-date-time/event-wizard-date-time.types';
import {areDatesEqual, convertTimeFromTimezoneToUTC, getTimeDifferenceIntervals, hasDatePassed2, hasTimePassed, NUM_MILLISECONDS_PER_SECOND} from '@Utils/date.util';
import type {TimeZone} from 'timezones-list';
import type {RecurringEvent} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';

export const getMaxAllowedDate = (startDateTime: Date): Date => {
  const maxDate = new Date(startDateTime.getTime());
  maxDate.setDate(maxDate.getDate() + MAX_EVENT_LENGTH_DAYS);
  return maxDate;
};

export const getDateTimeErrorType = (startDateTime: Date, endDateTime: Date, timezone: TimeZone, isTimezoneUTC = false): null | DateTimeErrorType => {
  if (startDateTime >= endDateTime) {
    if (startDateTime.getDate() > endDateTime.getDate()) {
      return DateTimeErrorType.END_DATE_GREATER;
    }
    return DateTimeErrorType.END_TIME_GREATER;
  }
  let endDateTimeUTC = endDateTime;

  if (!isTimezoneUTC) {
    endDateTimeUTC = convertTimeFromTimezoneToUTC(endDateTime, timezone.utc);
  }

  if (hasDatePassed2(endDateTimeUTC)) {
    if (hasTimePassed(endDateTimeUTC)) {
      return DateTimeErrorType.EVENT_PASSED_TIME;
    }
    return DateTimeErrorType.EVENT_PASSED_DATE;
  }
  return null;
};

export const getDateTimeErrorText = (startDateTime: Date, endDateTime: Date, timezone: TimeZone): string => {
  const errType: null | DateTimeErrorType = getDateTimeErrorType(startDateTime, endDateTime, timezone);

  if (errType === null) {
    return '';
  }

  if (errType === DateTimeErrorType.EVENT_PASSED_DATE) {
    return window.i18next.t('pmwjs_event_date_passed');
  }

  if (errType === DateTimeErrorType.EVENT_PASSED_TIME) {
    return window.i18next.t('pmwjs_event_time_passed');
  }

  if (errType === DateTimeErrorType.END_DATE_GREATER) {
    return window.i18next.t('pmwjs_end_date_greater_than_start');
  }
  return window.i18next.t('pmwjs_end_time_greater_than_start');
};

export const isEventLengthValid = (startDateTime: Date, endDateTime: Date): boolean => {
  const differenceInSeconds = Math.floor((endDateTime.getTime() - startDateTime.getTime()) / NUM_MILLISECONDS_PER_SECOND);
  return getTimeDifferenceIntervals(differenceInSeconds).intervalInDays <= MAX_EVENT_LENGTH_DAYS;
};

/**
 * For a recurring event, checks if the dates are equal
 * @param recurringPattern
 * @param startDate
 * @param endDate
 */
export const shouldEnsureRecurringEventIsOnSameDay = (recurringPattern: RecurringEvent, startDate: Date, endDate: Date): boolean => {
  return recurringPattern.isRecurring && !areDatesEqual(startDate, endDate);
};

export const makeDatesEqualForRecurringEventFromStartUpdate = (startDateTime: Date): Date[] => {
  const endDateTime = new Date(startDateTime.getTime());

  if (startDateTime.getHours() < 23) {
    endDateTime.setHours(startDateTime.getHours() + 1);
    return [startDateTime, endDateTime];
  }

  if (startDateTime.getMinutes() < 45) {
    endDateTime.setMinutes(startDateTime.getMinutes() + 15);
    return [startDateTime, endDateTime];
  }
  endDateTime.setMinutes(59);

  if (startDateTime.getMinutes() === 59) {
    startDateTime.setMinutes(58);
  }
  return [startDateTime, endDateTime];
};

export const makeDatesEqualForRecurringEventFromEndUpdate = (endDateTime: Date): Date[] => {
  const startDateTime = new Date(endDateTime.getTime());

  if (endDateTime.getHours() > 0) {
    startDateTime.setHours(endDateTime.getHours() - 1);
    return [startDateTime, endDateTime];
  }

  if (endDateTime.getMinutes() >= 15) {
    startDateTime.setMinutes(endDateTime.getMinutes() - 15);
    return [startDateTime, endDateTime];
  }
  startDateTime.setMinutes(0);

  if (endDateTime.getMinutes() === 0) {
    endDateTime.setMinutes(1);
  }
  return [startDateTime, endDateTime];
};
