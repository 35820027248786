import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {EventWizardReduxState} from '@Components/event-wizard/event-wizard.types';
import {EventWizardProgressStep, EventWizardSourceType, orderedProgressSteps} from '@Components/event-wizard/event-wizard.types';
import type {RecurringEvent} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import {RecurringType} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import {WEEKDAY_NUMS} from '@Components/event-wizard/month-day-selectors/month-day-selectors.types';
import {ALL_WEEKDAYS_USA, Weekdays} from '@Components/event-wizard/week-day-selectors/week-day-selectors.types';
import {addHoursToDate} from '@Components/event-wizard/event-wizard.helper';
import type {EventWizardVenue} from '@Components/event-wizard/event-wizard-venue/event-wizard-venue.types';
import {EventVenueType} from '@Components/event-wizard/event-wizard-venue/event-wizard-venue.types';
import type {EventType, EventTypes} from '@Components/event-wizard/type-dropdown/type-dropdown.types';
import type {TimeZone} from 'timezones-list';
import timezones from 'timezones-list';
import type {PlaceDetails} from '@Components/google-map/google-map.types';
import type {EventRegistrationFields} from '@Components/event-wizard/event-wizard-registrations/event-wizard-registrations.types';
import {getRegistrationButtonTextOptions, shouldUpsellUserForRegistrationOptions} from '@Components/event-wizard/event-wizard-registrations/event-wizard-registrations.types';
import type {EventWizardOrganisers, OrganiserDetails} from '@Components/event-wizard/event-wizard-organisers/event-wizard-organisers.types';
import type {CoverPhoto} from '@Components/event-wizard/event-wizard-image-video/event-wizard-image-video.types';
import type {EventStyleColors, EventStyles} from '@Components/event-wizard/event-style/event-style.types';
import {PMW_COLORS_PRIMARY} from '@Utils/color.util';
import {getNewStyleColors} from '@Components/event-wizard/event-style/event-style.helper';
import {initSocialMediaProfileLinks} from '@Components/social-media-profile-links/social-media-profile-links-library';
import {DateTimeMobilePanel} from '@Panels/event-wizard-date-time-panel/event-wizard-date-time-panel.types';
import {getUTCOffsetMinutesFromTimeZone} from '@Components/event-wizard/timezone-content/timezone-content.helper';
import {shouldUpsellUserForTeamShare} from '@Components/event-wizard/event-wizard-other-options/event-wizard-other-options.types';
import {getMaxRecurringDate} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.helper';
import {DEFAULT_EVENTS_STYLE_FONT_FAMILY} from '@Libraries/events.library';

const getDefaultEventTypes = (): EventTypes => {
  return {
    allTypes: [],
  };
};

export const getDefaultRecurringEvent = (): RecurringEvent => {
  const defaultRecurringType = RecurringType.DAILY;
  const defaultStartDateTime = getDefaultStartDateTime();

  return {
    isRecurring: false,
    recurringType: defaultRecurringType,
    recurringEndDate: getMaxRecurringDate(defaultStartDateTime, defaultRecurringType),
    recurringMonthly: {
      weekdayNum: WEEKDAY_NUMS[0],
      weekday: Weekdays.MONDAY,
    },
    recurringWeeks: [ALL_WEEKDAYS_USA[defaultStartDateTime.getDay()]],
  };
};

export const getDefaultTimeZone = (): TimeZone => {
  const findUserTimeZone = (userTimeZoneCode: string): TimeZone | undefined => {
    return timezones.find((tz: TimeZone) => {
      return tz.tzCode === userTimeZoneCode;
    });
  };

  const userTimeZone = findUserTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);

  if (userTimeZone) {
    return userTimeZone;
  }
  const userUTCOffsetMins = -1 * new Date().getTimezoneOffset();

  const findApproximateUserTimeZoneFromUTC = (utcOffsetMins: number): TimeZone | undefined => {
    return timezones.find((tz: TimeZone) => {
      return getUTCOffsetMinutesFromTimeZone(tz) === utcOffsetMins;
    });
  };

  return findApproximateUserTimeZoneFromUTC(userUTCOffsetMins) ?? timezones[0];
};

export const getDefaultRegistrationDetails = (): EventRegistrationFields => {
  return {
    email: true,
    name: !shouldUpsellUserForRegistrationOptions(),
    phone: false,
    registerBtnText: getRegistrationButtonTextOptions()[0],
  };
};

export const getDefaultOrganiserDetails = (): EventWizardOrganisers => {
  return {
    allOrganisers: [],
    selectedOrganiser: getDefaultSelectedOrganiserDetails(),
  };
};

export const getDefaultSelectedOrganiserDetails = (): OrganiserDetails => {
  return {
    hashedId: undefined,
    name: '',
    email: '',
    phone: undefined,
    profilePic: '',
    isProfilePicturePrefilled: false,
    socialMediaLinks: initSocialMediaProfileLinks(),
  };
};

const getDefaultStyles = (): EventStyles => {
  return {
    colors: getNewStyleColors(PMW_COLORS_PRIMARY.PRIMARY_100),
    fontFamily: DEFAULT_EVENTS_STYLE_FONT_FAMILY,
  };
};

const getDefaultVenue = (): EventWizardVenue => {
  return {
    selectedVenueType: EventVenueType.OFFLINE,
  };
};

export const getDefaultStartDateTime = (): Date => {
  const startDate = new Date();
  startDate.setHours(startDate.getHours() + 1);
  startDate.setMinutes(0);
  return startDate;
};

export const getDefaultEndDateTime = (): Date => {
  return addHoursToDate(getDefaultStartDateTime());
};

const eventWizardInitialState: EventWizardReduxState = {
  eventHID: undefined,
  googleMapAPIKey: '',
  title: '',
  types: getDefaultEventTypes(),
  description: '',
  startDateTime: getDefaultStartDateTime(),
  endDateTime: getDefaultEndDateTime(),
  timeZone: getDefaultTimeZone(),
  recurringEvent: getDefaultRecurringEvent(),
  showCountdown: true,
  venue: getDefaultVenue(),
  userPastVenues: [],
  registrationDetails: getDefaultRegistrationDetails(),
  isRemoveWatermark: false,
  isSharedInTeam: !shouldUpsellUserForTeamShare(),
  isSharedAsGoogleEvent: false,
  organisers: getDefaultOrganiserDetails(),
  coverPhoto: {},
  shouldCheckIfInErrState: false,
  styles: getDefaultStyles(),
  teamName: undefined,
  currentProgressStep: orderedProgressSteps[0],
  isEventBeingCreated: false,
  shouldNextBtnBeDisabled: false,
  shouldUpdateExpandedReviewSection: false,
  dateTimeMobilePanel: DateTimeMobilePanel.DEFAULT,
  pageSourceType: EventWizardSourceType.DEFAULT,
  isDesignPrefillContentLoading: false,
  progressStepLandedOn: EventWizardProgressStep.OVERVIEW,
};
const eventWizardSlice = createSlice({
  name: 'eventWizardSlice',
  initialState: eventWizardInitialState,
  reducers: {
    onEventWizardEventHIDChange: (state, action: PayloadAction<string>): void => {
      state.eventHID = action.payload;
    },
    onEventWizardGoogleMapAPIKeyChange: (state, action: PayloadAction<string>): void => {
      state.googleMapAPIKey = action.payload;
    },
    onEventWizardTitleChange: (state, action: PayloadAction<string>): void => {
      state.title = action.payload;
    },
    onEventWizardAllTypesChange: (state, action: PayloadAction<EventType[]>): void => {
      state.types.allTypes = action.payload;
    },
    onEventWizardSelectedTypeChange: (state, action: PayloadAction<EventType | undefined>): void => {
      state.types.selectedType = action.payload;
    },
    onEventWizardAITypeChange: (state, action: PayloadAction<EventType | undefined>): void => {
      state.types.aiType = action.payload;
    },
    onEventWizardIsAIEventLoading: (state, action: PayloadAction<boolean>): void => {
      state.types.isAITypeLoading = action.payload;
    },
    onEventWizardDescriptionChange: (state, action: PayloadAction<string>): void => {
      state.description = action.payload;
    },
    onEventWizardStartDateTimeUpdate: (state, action: PayloadAction<Date>): void => {
      state.startDateTime = action.payload;
    },
    onEventWizardEndDateTimeUpdate: (state, action: PayloadAction<Date>): void => {
      state.endDateTime = action.payload;
    },
    onEventWizardTimeZoneUpdate: (state, action: PayloadAction<TimeZone>): void => {
      state.timeZone = action.payload;
    },
    onEventWizardToggleCountdown: (state, action: PayloadAction<boolean>): void => {
      state.showCountdown = action.payload;
    },
    onEventWizardRecurringChange: (state, action: PayloadAction<RecurringEvent>): void => {
      state.recurringEvent = action.payload;
    },
    onEventWizardToggleRecurringChange: (state, action: PayloadAction<boolean>): void => {
      state.recurringEvent.isRecurring = action.payload;
    },
    onEventWizardRecurringTypeChange: (state, action: PayloadAction<RecurringType>): void => {
      state.recurringEvent.recurringType = action.payload;
    },
    onEventWizardRecurringEndsOnChange: (state, action: PayloadAction<Date>): void => {
      state.recurringEvent.recurringEndDate = action.payload;
    },
    onEventWizardRecurringMonthWeekdayOccurenceChange: (state, action: PayloadAction<string>): void => {
      state.recurringEvent.recurringMonthly.weekdayNum = action.payload;
    },
    onEventWizardRecurringMonthWeekdayChange: (state, action: PayloadAction<Weekdays>): void => {
      state.recurringEvent.recurringMonthly.weekday = action.payload;
    },
    onEventWizardRecurringWeeklyChange: (state, action: PayloadAction<Weekdays[]>): void => {
      state.recurringEvent.recurringWeeks = action.payload;
    },
    onEventWizardVenueTypeChange: (state, action: PayloadAction<EventVenueType | undefined>): void => {
      state.venue.selectedVenueType = action.payload;
    },
    onEventWizardUserPastOfflineVenueChange: (state, action: PayloadAction<PlaceDetails[]>): void => {
      state.userPastVenues = action.payload;
    },
    onEventWizardOfflineVenueChange: (state, action: PayloadAction<PlaceDetails | undefined>): void => {
      if (action.payload) {
        state.venue.selectedVenueType = EventVenueType.OFFLINE;
        state.venue.offlineVenue = action.payload;
        return;
      }
      state.venue.selectedVenueType = undefined;
      state.venue.offlineVenue = undefined;
    },
    onEventWizardOnlineVenueChange: (state, action: PayloadAction<string | undefined>): void => {
      if (action.payload && action.payload !== '') {
        state.venue.selectedVenueType = EventVenueType.ONLINE;
        state.venue.onlineVenueURL = action.payload;
        return;
      }
      state.venue.selectedVenueType = undefined;
      state.venue.onlineVenueURL = undefined;
    },
    onEventWizardRegistrationChange: (state, action: PayloadAction<EventRegistrationFields>): void => {
      state.registrationDetails = action.payload;
    },
    onEventWizardRemoveWatermarkToggle: (state, action: PayloadAction<boolean>): void => {
      state.isRemoveWatermark = action.payload;
    },
    onEventWizardShareInTeamToggle: (state, action: PayloadAction<boolean>): void => {
      state.isSharedInTeam = action.payload;
    },
    onEventWizardShareAsGoogleEventToggle: (state, action: PayloadAction<boolean>): void => {
      state.isSharedAsGoogleEvent = action.payload;
    },
    onEventWizardOrganiserUpdate: (state, action: PayloadAction<EventWizardOrganisers>): void => {
      state.organisers = action.payload;
    },
    onEventWizardSelectedOrganiserUpdate: (state, action: PayloadAction<OrganiserDetails>): void => {
      state.organisers.selectedOrganiser = action.payload;
    },
    onEventWizardCoverPhotoUpdate: (state, action: PayloadAction<CoverPhoto>): void => {
      state.coverPhoto = action.payload;
    },
    onEventWizardIsCoverPhotoPreviewLoading: (state, action: PayloadAction<boolean>): void => {
      state.coverPhoto.isPreviewLoading = action.payload;
    },
    onEventWizardCheckErrorStateUpdate: (state, action: PayloadAction<boolean>): void => {
      state.shouldCheckIfInErrState = action.payload;
    },
    onEventWizardStyleUpdate: (state, action: PayloadAction<EventStyles>): void => {
      state.styles = action.payload;
    },
    onEventWizardStyleFontUpdate: (state, action: PayloadAction<string>): void => {
      state.styles.fontFamily = action.payload;
    },
    onEventWizardStyleColorUpdate: (state, action: PayloadAction<EventStyleColors>): void => {
      state.styles.colors = action.payload;
    },
    onEventWizardTeamNameUpdate: (state, action: PayloadAction<string | undefined>): void => {
      state.teamName = action.payload;
    },
    onEventWizardProgressStepUpdate: (state, action: PayloadAction<EventWizardProgressStep>): void => {
      state.currentProgressStep = action.payload;
    },
    onEventWizardIsEventBeingCreatedUpdate: (state, action: PayloadAction<boolean>): void => {
      state.isEventBeingCreated = action.payload;
    },
    onEventWizardShouldNextBtnBeDisabled: (state, action: PayloadAction<boolean>): void => {
      state.shouldNextBtnBeDisabled = action.payload;
    },
    onEventWizardUpdateShouldExpandReviewSection: (state, action: PayloadAction<boolean>): void => {
      state.shouldUpdateExpandedReviewSection = action.payload;
    },
    onEventWizardDateTimeMobilePanelUpdate: (state, action: PayloadAction<DateTimeMobilePanel>): void => {
      state.dateTimeMobilePanel = action.payload;
    },
    onEventWizardPageSourceTypeUpdate: (state, action: PayloadAction<EventWizardSourceType>): void => {
      state.pageSourceType = action.payload;
    },
    onEventWizardIsDesignPrefillContentLoadingUpdate: (state, action: PayloadAction<boolean>): void => {
      state.isDesignPrefillContentLoading = action.payload;
    },
    onEventWizardProgressStepLandedOnUpdate: (state, action: PayloadAction<EventWizardProgressStep>): void => {
      state.progressStepLandedOn = action.payload;
    },
  },
});

export const {
  onEventWizardEventHIDChange,
  onEventWizardGoogleMapAPIKeyChange,
  onEventWizardTitleChange,
  onEventWizardAllTypesChange,
  onEventWizardSelectedTypeChange,
  onEventWizardAITypeChange,
  onEventWizardIsAIEventLoading,
  onEventWizardDescriptionChange,
  onEventWizardStartDateTimeUpdate,
  onEventWizardEndDateTimeUpdate,
  onEventWizardTimeZoneUpdate,
  onEventWizardRecurringChange,
  onEventWizardToggleRecurringChange,
  onEventWizardRecurringTypeChange,
  onEventWizardRecurringEndsOnChange,
  onEventWizardRecurringMonthWeekdayChange,
  onEventWizardRecurringMonthWeekdayOccurenceChange,
  onEventWizardRecurringWeeklyChange,
  onEventWizardToggleCountdown,
  onEventWizardUserPastOfflineVenueChange,
  onEventWizardVenueTypeChange,
  onEventWizardOfflineVenueChange,
  onEventWizardOnlineVenueChange,
  onEventWizardRegistrationChange,
  onEventWizardRemoveWatermarkToggle,
  onEventWizardShareInTeamToggle,
  onEventWizardShareAsGoogleEventToggle,
  onEventWizardOrganiserUpdate,
  onEventWizardSelectedOrganiserUpdate,
  onEventWizardCoverPhotoUpdate,
  onEventWizardIsCoverPhotoPreviewLoading,
  onEventWizardCheckErrorStateUpdate,
  onEventWizardStyleUpdate,
  onEventWizardStyleFontUpdate,
  onEventWizardStyleColorUpdate,
  onEventWizardTeamNameUpdate,
  onEventWizardProgressStepUpdate,
  onEventWizardIsEventBeingCreatedUpdate,
  onEventWizardShouldNextBtnBeDisabled,
  onEventWizardUpdateShouldExpandReviewSection,
  onEventWizardPageSourceTypeUpdate,
  onEventWizardDateTimeMobilePanelUpdate,
  onEventWizardIsDesignPrefillContentLoadingUpdate,
  onEventWizardProgressStepLandedOnUpdate,
} = eventWizardSlice.actions;
export const eventWizardReducer = eventWizardSlice.reducer;
