import {RecurringEvent} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import {TimeZone} from 'timezones-list';

export interface PreviewProps {
  isMobileScreenSize?: boolean;
}

export const PREVIEW_IFRAME_ID = 'previewIframeID';

// Cover Photo
export const COVER_PHOTO_CONTAINER_ID = 'coverPhotoContainerID';
export const COVER_PHOTO_ID = 'coverPhotoID';
export const COVER_PHOTO_PLACEHOLDER_ICON_ID = 'coverPhotoIconPlaceholderID';
export const COVER_PHOTO_BACKGROUND_ID = 'coverPhotoBgID';

// Title
export const TITLE_ID = 'titleID';

// Description
export const DESCRIPTION_ID = 'descriptionID';

// Venue Summary
export const VENUE_SUMMARY_TITLE = 'venueSummaryTitle';
export const VENUE_SUMMARY_SUBTITLE = 'venueSummarySubtitle';
export const VENUE_ICON_EMPTY_ID: string = 'venueIconEmptyID';
export const VENUE_ICON_MAP_ID: string = 'venueIconMapID';
export const VENUE_ICON_GOOGLE_MEET_ID: string = 'venueIconGoogleMeetID';
export const VENUE_ICON_ZOOM_ID: string = 'venueIconZoomID';
export const VENUE_ICON_ONLINE_ID: string = 'venueIconOnlineID';

// Venue
export const VENUE_MAP_ID = 'venueMapID';
export const VENUE_MAP_LOCATION_ID = 'venueMapLocationID';
export const VENUE_MAP_NAME_ID = 'venueMapNameID';
export const VENUE_MAP_ADDRESS_ID = 'venueMapAddressID';
export const VENUE_MAP_ADDRESS_DIRECTIONS_ID = 'venueMapAddressDirectionsID';

export const VENUE_ONLINE_ID = 'venueOnlineID';
export const VENUE_ONLINE_GOOGLE_MEET_ID = 'venueOnlineGoogleMeetID';
export const VENUE_ONLINE_ZOOM_ID = 'venueOnlineZoomID';

// Date Time
export const DATE_TIME_EMPTY_ID = 'dateTimeEmptyID';
export const DATE_TIME_SINGLE_DAY_ID = 'dateTimeSingleDayID';
export const DATE_TIME_MULTIDAY_ID = 'dateTimeMultidayID';
export const DATE_TIME_RECURRING_DAILY_ID = 'dateTimeRecurDailyID';
export const DATE_TIME_RECURRING_WEEKLY_SINGLE_ID = 'dateTimeRecurWeeklySingleID';
export const DATE_TIME_RECURRING_WEEKLY_MULTI_ID = 'dateTimeRecurWeeklyMultiID';
export const DATE_TIME_RECURRING_MONTHLY_ID = 'dateTimeRecurMonthlyID';

export const DATE_TIME_ICON_START_MONTH_ID = 'dateTimeIconStartMonthID';
export const DATE_TIME_ICON_START_DATE_ID = 'dateTimeIconStartDateID';
export const DATE_TIME_START_TITLE_ID = 'dateTimeStartTitleID';
export const DATE_TIME_MOBILE_START_TITLE_ID = 'dateTimeMobileStartTitleID';
export const DATE_TIME_START_SUBTITLE_ID = 'dateTimeStartSubtitleID';
export const DATE_TIME_END_TITLE_ID = 'dateTimeEndTitleID';
export const DATE_TIME_MOBILE_END_TITLE_ID = 'dateTimeMobileEndTitleID';
export const DATE_TIME_END_SUBTITLE_ID = 'dateTimeEndSubtitleID';
export const DATE_TIME_TIMEZONE_ID = 'dateTimeTimeZoneID';

// Countdown
export const CUSTOM_EVENT_COUNTDOWN_WIZARD_UPDATED = 'countdown_updated_wizard';
export const COUNTDOWN_ID = 'countdownID';
export const COUNTDOWN_DAYS_CONTAINER_ID = 'countdownDaysContainerID';
export const COUNTDOWN_HOURS_CONTAINER_ID = 'countdownHoursContainerID';
export const COUNTDOWN_MINS_CONTAINER_ID = 'countdownMinsContainerID';
export const COUNTDOWN_SECS_CONTAINER_ID = 'countdownSecsContainerID';
export const COUNTDOWN_TIME_ID = 'countdownTimeID';

// Organiser
export const ORGANISER_SECTION = 'organiserSectionID';
export const ORGANISER_PROFILE_NAME_SECTION = 'organiserProfileNameSectionID';
export const ORGANISER_CONTACT_SECTION = 'organiserContactSectionID';
export const ORGANISER_PROFILE_PICTURE = 'organiserProfilePictureID';
export const ORGANISER_NAME = 'organiserNameID';
export const ORGANISER_EMAIL = 'organiserEmailID';
export const ORGANISER_PHONE = 'organiserPhoneID';
export const ORGANISER_SOCIAL_CONTAINER = 'organiserSocialContainerID';
export const ORGANISER_SOCIAL_FACEBOOK = 'organiserSocialFacebookID';
export const ORGANISER_SOCIAL_INSTAGRAM = 'organiserSocialInstagramID';
export const ORGANISER_SOCIAL_LINKEDIN = 'organiserSocialLinkedinID';
export const ORGANISER_SOCIAL_X = 'organiserSocialXID';
export const ORGANISER_SOCIAL_PINTEREST = 'organiserSocialPinterestID';
export const ORGANISER_SOCIAL_YOUTUBE = 'organiserSocialYoutubeID';
export const ORGANISER_SOCIAL_TIKTOK = 'organiserSocialTiktokID';

// Registration form
export const REGISTRATION_FORM_ID = 'registrationFromID';
export const REGISTRATION_CTA_ID = 'registrationCTAID';
export const REGISTRATION_FORM_FIRST_NAME_ID = 'registrationFormFirstNameID';
export const REGISTRATION_FORM_LAST_NAME_ID = 'registrationFormLastNameID';
export const REGISTRATION_FORM_PHONE_NUMBER_ID = 'registrationFormPhoneNumberID';
export const REGISTRATION_FORM_CTA_TEXT_ID = 'registrationFormCTATextID';
export const REGISTRATION_FORM_HEADING_TEXT_ID = 'registrationFormHeadingTextID';

// Watermark
export const WATERMARK_ID = 'watermarkID';

// Styling
export const STYLE_LIGHTER_BG_COLOR_ID = 'styleLighterBgColorID';
export const STYLE_LIGHT_BG_COLOR_ID = 'styleLightBgColorID';
export const STYLE_COLOR_ID = 'styleColorID';
export const STYLE_DARK_TEXT_COLOR_ID = 'styleDarkColorID';

export interface EventPreviewDateTime {
  startDateTime: Date;
  endDateTime: Date;
  recurringPattern?: RecurringEvent;
  timeZone: TimeZone;
}
