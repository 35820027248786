export interface EventWizardDateTimeProps {
  // add props here
}

export enum DateTimeErrorType {
  END_DATE_GREATER = 0,
  END_TIME_GREATER = 1,
  EVENT_PASSED_TIME = 3,
  EVENT_PASSED_DATE = 4,
}

export const MAX_EVENT_LENGTH_DAYS = 3;

export const startTimeInputDropdownID = 'startTimeInputDropdownID';
export const endTimeInputDropdownID = 'endTimeInputDropdownID';
