import type {EventWizardReduxState} from '@Components/event-wizard/event-wizard.types';
import {MESSAGE_GROWL_KEY} from '@Components/event-wizard/event-wizard.types';
import {LOADING_TOAST_KEY} from '@Components/event-wizard/event-wizard.types';
import {EventCreationErrorType, EventWizardProgressStep, EventWizardSourceType} from '@Components/event-wizard/event-wizard.types';
import type {RecurringEvent} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import {RecurringType} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import type {PlaceDetails} from '@Components/google-map/google-map.types';
import type {EventRegistrationFields} from '@Components/event-wizard/event-wizard-registrations/event-wizard-registrations.types';
import type {OrganiserDetails} from '@Components/event-wizard/event-wizard-organisers/event-wizard-organisers.types';
import type {CoverPhoto} from '@Components/event-wizard/event-wizard-image-video/event-wizard-image-video.types';
import {MediaTypes} from '@Components/event-wizard/event-wizard-image-video/event-wizard-image-video.types';
import type {EventStyles} from '@Components/event-wizard/event-style/event-style.types';
import {redirectUser} from '@Utils/browser.util';
import type {EventWizardVenue} from '@Components/event-wizard/event-wizard-venue/event-wizard-venue.types';
import {EventVenueType, isOfflineVenueComplete} from '@Components/event-wizard/event-wizard-venue/event-wizard-venue.types';
import type {EventVO, EventVOMinifiedWizardPrefill} from '@Components/events/events.types';
import {
  getDefaultEndDateTime,
  getDefaultRecurringEvent,
  getDefaultSelectedOrganiserDetails,
  getDefaultStartDateTime,
  getDefaultTimeZone,
  onEventWizardCoverPhotoUpdate,
  onEventWizardDescriptionChange,
  onEventWizardEndDateTimeUpdate,
  onEventWizardEventHIDChange,
  onEventWizardIsDesignPrefillContentLoadingUpdate,
  onEventWizardOfflineVenueChange,
  onEventWizardOnlineVenueChange,
  onEventWizardProgressStepLandedOnUpdate,
  onEventWizardProgressStepUpdate,
  onEventWizardRecurringChange,
  onEventWizardRegistrationChange,
  onEventWizardRemoveWatermarkToggle,
  onEventWizardSelectedOrganiserUpdate,
  onEventWizardSelectedTypeChange,
  onEventWizardShareAsGoogleEventToggle,
  onEventWizardShareInTeamToggle,
  onEventWizardStartDateTimeUpdate,
  onEventWizardStyleUpdate,
  onEventWizardTimeZoneUpdate,
  onEventWizardTitleChange,
  onEventWizardToggleCountdown,
} from '@Components/event-wizard/event-wizard-slice';
import type {TimeZone} from 'timezones-list';
import timezones from 'timezones-list';
import {convertTimeFromTimezoneToUTC, convertUTCTimeToGivenTimezone, getSQLFormatDate, getTimeDifferenceIntervals, hasDatedPassed} from '@Utils/date.util';
import {getDateTimeErrorType} from '@Components/event-wizard/event-wizard-date-time/event-wizard-date-time.helper';
import {DateTimeErrorType} from '@Components/event-wizard/event-wizard-date-time/event-wizard-date-time.types';
import {EventPageSource, getEventPageURLWithSource, openUpsellDialogForEventsLimitReached} from '@Libraries/events.library';
import {isInteger, isNaN} from 'lodash';
import {getPosterPreviewURL} from '@Libraries/poster-library';
import {togglePreviewCoverPhotoLoading, togglePreviewTitleLoading, updatePreviewDateTime, updatePreviewTitle} from '@Components/event-wizard/preview/preview.helper';
import {isBasicSectionComplete} from '@Components/event-wizard/event-wizard-review/event-wizard-review.helper';
import {hideLoading, showLoading, smoothEstimateLoadingProgress, updateLoadingProgress} from '@Libraries/loading-toast-library';
import {GROWL_TYPE, showMessageGrowl} from '@Components/message-growl';

export const goToOriginPage = (): void => {
  window.history.back();
};

export const removeRedundantQueryParams = (): void => {
  history.replaceState({}, '', window.location.origin + window.location.pathname);
};

/**
 * Checks if an element has a class or is inside a parent class
 * @param element
 * @param classNameToExclude
 */
export const isElementInClass = (element: HTMLElement | null, classNameToExclude: string): boolean => {
  let elementRecur = element;

  while (elementRecur) {
    if (elementRecur.classList.contains(classNameToExclude)) {
      return true;
    }
    elementRecur = elementRecur.parentElement;
  }
  return false;
};

export const setReduxForDesignPrefill = (eventVOMinifiedWizardPrefill?: EventVOMinifiedWizardPrefill): void => {
  const designTemplateSource: MediaTypes | undefined = eventVOMinifiedWizardPrefill?.designTemplateSource;

  if (
    !eventVOMinifiedWizardPrefill?.designTemplateHID ||
    !eventVOMinifiedWizardPrefill.designTemplateURL ||
    designTemplateSource === undefined ||
    !(designTemplateSource in MediaTypes)
  ) {
    return;
  }

  const {dispatch} = window.PMW.redux.store;

  dispatch(onEventWizardIsDesignPrefillContentLoadingUpdate(true));

  dispatch(
    onEventWizardCoverPhotoUpdate({
      uploadedUrl: eventVOMinifiedWizardPrefill.designTemplateURL,
      selectedTemplateHID: eventVOMinifiedWizardPrefill.designTemplateHID,
      mediaType: eventVOMinifiedWizardPrefill.designTemplateSource,
    })
  );
  prefillContentFromDesign(eventVOMinifiedWizardPrefill);
};

export const setReduxForStartDatePrefill = (eventVOMinifiedWizardPrefill?: EventVOMinifiedWizardPrefill): void => {
  if (!eventVOMinifiedWizardPrefill?.startDateTime) {
    return;
  }
  const {dispatch} = window.PMW.redux.store;
  const timeZone = getDefaultTimeZone();
  const startDateTime = new Date(eventVOMinifiedWizardPrefill.startDateTime);
  const endDateTime = addHoursToDate(startDateTime);
  const endDateTimeUTC = convertTimeFromTimezoneToUTC(endDateTime, timeZone.utc);

  if (hasDatedPassed(endDateTimeUTC)) {
    return;
  }
  dispatch(onEventWizardStartDateTimeUpdate(startDateTime));
  dispatch(onEventWizardEndDateTimeUpdate(endDateTime));
};

export const setReduxForUpdatingEvent = (eventVO?: EventVO): void => {
  if (!eventVO) {
    return;
  }
  const {dispatch} = window.PMW.redux.store;

  dispatch(onEventWizardEventHIDChange(eventVO.hashedID));
  dispatch(onEventWizardTitleChange(eventVO.title));
  dispatch(onEventWizardSelectedTypeChange(eventVO.type));
  dispatch(onEventWizardDescriptionChange(eventVO.description));
  setReduxCoverPhotoFromUpdateEvent(eventVO);
  setReduxVenueFromUpdateEvent(eventVO);
  setReduxRecurringForUpdateEvent(eventVO.recurring);
  const timezone = setReduxTimezoneFromUpdateEvent(eventVO.tzcode);
  attemptToSetReduxDateTimeFromGivenStartAndEnd(new Date(eventVO.startDateTime), new Date(eventVO.endDateTime), timezone);
  dispatch(onEventWizardToggleCountdown(eventVO.showCountdown));
  dispatch(onEventWizardStyleUpdate(eventVO.style));
  setReduxOrganiserForUpdateEvent(eventVO);
  dispatch(onEventWizardShareInTeamToggle(eventVO.isShared));
  dispatch(onEventWizardRemoveWatermarkToggle(eventVO.isRemoveWatermark));
  dispatch(onEventWizardRegistrationChange(eventVO.registrationFields));
  dispatch(onEventWizardShareAsGoogleEventToggle(!!eventVO.isSharedOnGoogle));
  dispatch(onEventWizardSelectedOrganiserUpdate(eventVO.organiser ?? getDefaultSelectedOrganiserDetails()));
  dispatch(onEventWizardProgressStepUpdate(EventWizardProgressStep.REVIEW));
  dispatch(onEventWizardProgressStepLandedOnUpdate(EventWizardProgressStep.REVIEW));
};

export const doesContainKeywords = (searchText: string, keywords: string): boolean => {
  return searchText.toLowerCase().includes(keywords.toLowerCase());
};

export const addHoursToDate = (date: Date, hours = 1): Date => {
  return new Date(date.getTime() + hours * 60 * 60 * 1000);
};

export const saveEvent = async (data: EventWizardReduxState, onFailCB: () => void): Promise<void> => {
  return window.PMW.writeLocal('event/saveEvent', prepareDataForEventCreation(data))
    .then(onEventSuccessCreation)
    .catch((error: Error) => {
      onEventFailedCreation(error);
      onFailCB();
    });
};

const onEventSuccessCreation = (event: EventVO): void => {
  redirectUser(getEventPageURLWithSource(EventPageSource.FROM_WIZARD_COMPLETION, event), false, false);
};

const onEventFailedCreation = (error: Error): void => {
  const errMsg = error.message;

  if (errMsg === EventCreationErrorType.NUM_CREATION_LIMIT.toString()) {
    openUpsellDialogForEventsLimitReached();
    return;
  }
  console.log('selfCodeReviewJibran: error dialog');
};

const setReduxCoverPhotoFromUpdateEvent = (eventVO: EventVO): void => {
  let url = eventVO.img,
    mediaType = MediaTypes.MY_DESIGNS;

  if (eventVO.coverPhotoTemplateHID) {
    url = getPosterPreviewURL(eventVO.coverPhotoTemplateHID);
  } else {
    mediaType = MediaTypes.UPLOAD;
  }
  window.PMW.redux.store.dispatch(onEventWizardCoverPhotoUpdate({mediaType: mediaType, uploadedUrl: url, selectedTemplateHID: eventVO.coverPhotoTemplateHID}));
};

const setReduxVenueFromUpdateEvent = (eventVO: EventVO): void => {
  const {dispatch} = window.PMW.redux.store;

  if (eventVO.venueOnline) {
    dispatch(onEventWizardOnlineVenueChange(eventVO.venueOnline.link));
    return;
  }

  if (eventVO.venue && isOfflineVenueComplete(eventVO.venue)) {
    dispatch(onEventWizardOfflineVenueChange(eventVO.venue));
  }
};

const setReduxTimezoneFromUpdateEvent = (tzcode: string): TimeZone => {
  for (const timezone of timezones) {
    if (timezone.tzCode === tzcode) {
      window.PMW.redux.store.dispatch(onEventWizardTimeZoneUpdate(timezone));
      return timezone;
    }
  }
  return getDefaultTimeZone();
};

const setReduxRecurringForUpdateEvent = (recurring: RecurringEvent | undefined): void => {
  if (!recurring?.recurringEndDateStr) {
    return;
  }
  recurring.recurringEndDate = new Date(recurring.recurringEndDateStr);

  const defaultRecurring = getDefaultRecurringEvent();

  if (recurring.recurringType === RecurringType.WEEKLY) {
    recurring.recurringMonthly = defaultRecurring.recurringMonthly;
  } else if (recurring.recurringType === RecurringType.MONTHLY) {
    recurring.recurringWeeks = defaultRecurring.recurringWeeks;
  } else {
    recurring.recurringWeeks = defaultRecurring.recurringWeeks;
    recurring.recurringMonthly = defaultRecurring.recurringMonthly;
  }
  window.PMW.redux.store.dispatch(onEventWizardRecurringChange({...recurring, isRecurring: true}));
};

const attemptToSetReduxDateTimeFromGivenStartAndEnd = (startDateTimeUTC: Date, endDateTimeUTC: Date, timeZone: TimeZone): Date[] => {
  const {dispatch} = window.PMW.redux.store;

  const errType = getDateTimeErrorType(startDateTimeUTC, endDateTimeUTC, timeZone, true);
  let startDateTimeUpdated: Date = startDateTimeUTC;
  let endDateTimeUpdated: Date = endDateTimeUTC;

  if (errType && errType !== DateTimeErrorType.EVENT_PASSED_DATE && errType !== DateTimeErrorType.EVENT_PASSED_TIME) {
    return [];
  }

  if (errType) {
    // If the event has passed, it goes to the next day from the current day WHILE keeping the hour/minute data the same
    const {intervalInDays} = getTimeDifferenceIntervals((endDateTimeUTC.getTime() - startDateTimeUTC.getTime()) / 1000);
    const currentTimeInUTC = convertTimeFromTimezoneToUTC(new Date(), timeZone.utc);
    startDateTimeUpdated = new Date(startDateTimeUTC.getTime());
    startDateTimeUpdated.setDate(currentTimeInUTC.getDate() + 1);
    endDateTimeUpdated = new Date(endDateTimeUTC.getTime());
    endDateTimeUpdated.setFullYear(startDateTimeUpdated.getFullYear(), startDateTimeUpdated.getMonth(), startDateTimeUpdated.getDate() + intervalInDays);
  }

  const userInputStartDateTime = convertUTCTimeToGivenTimezone(startDateTimeUpdated, timeZone.utc);
  const userInputEndDateTime = convertUTCTimeToGivenTimezone(endDateTimeUpdated, timeZone.utc);
  dispatch(onEventWizardStartDateTimeUpdate(userInputStartDateTime));
  dispatch(onEventWizardEndDateTimeUpdate(userInputEndDateTime));

  return [userInputStartDateTime, userInputEndDateTime];
};

const setReduxOrganiserForUpdateEvent = (eventVO: EventVO): void => {
  if (!eventVO.organiser) {
    return;
  }
  const {dispatch} = window.PMW.redux.store;
  dispatch(onEventWizardSelectedOrganiserUpdate({...eventVO.organiser, isProfilePicturePrefilled: !!eventVO.organiser.profilePic}));
};

const prepareDataForEventCreation = (data: EventWizardReduxState) => {
  const venueData = getVenueData(data.venue);
  const registrationFields = getRegistrationFieldsData(data.registrationDetails);
  const organiserData = getOrganisationData(data.organisers.selectedOrganiser);
  const coverPhotoData = getCoverPhotoData(data.coverPhoto);
  const styles = getStyleData(data.styles);

  return {
    event_hid: data.eventHID,
    title: data.title,
    event_type_hid: data.types?.selectedType?.hashedID,
    description: data.description,
    ...coverPhotoData,
    ...venueData,
    date_start: getEventCreationTimeFormat(data.startDateTime),
    date_end: getEventCreationTimeFormat(data.endDateTime),
    recurring_pattern: getRecurringPatternForPost(data.recurringEvent),
    timezone: data.timeZone.tzCode,
    show_countdown: data.showCountdown ? 1 : 0,
    ...styles,
    ...organiserData,
    is_shared_team: data.isSharedInTeam ? 1 : 0,
    is_remove_watermark: data.isRemoveWatermark ? 1 : 0,
    is_shared_google: data.isSharedAsGoogleEvent ? 1 : 0,
    ...registrationFields,
    is_duplicate: data.pageSourceType === EventWizardSourceType.DUPLICATE ? 1 : 0,
  };
};

const getVenueData = (venue: EventWizardVenue) => {
  if (venue.selectedVenueType === EventVenueType.OFFLINE) {
    return getOfflineVenueData(venue.offlineVenue);
  }

  if (venue.selectedVenueType === EventVenueType.ONLINE) {
    return getOnlineVenueData(venue.onlineVenueURL);
  }
  return null;
};

const getOnlineVenueData = (url: string | undefined) => {
  if (!url) {
    return null;
  }

  return {
    online_event_link: url,
  };
};

const getOfflineVenueData = (place: PlaceDetails | undefined) => {
  if (!place) {
    return null;
  }

  return {
    offline_venue: {
      venue_id: place.idAddress,
      venue_new_place_id: place.placeID,
      name: place.name,
      address: place.address,
      address_directions: place.addressDirections,
      lng: place.longitude,
      lat: place.latitude,
      country: place.country,
      province: place.province,
      city: place.city,
      postal_code: place.postalCode,
    },
  };
};

const getRegistrationFieldsData = (registrationFields: EventRegistrationFields) => {
  return {
    registration_fields: {
      name: registrationFields.name ? 1 : 0,
      phone: registrationFields.phone ? 1 : 0,
      register_btn_text: registrationFields.registerBtnText,
    },
  };
};

const getOrganisationData = (organiserDetails: OrganiserDetails) => {
  return {
    organiser_details: {
      hid: organiserDetails.hashedId,
      name: organiserDetails.name,
      email: organiserDetails.email,
      phone: organiserDetails.phone,
      pic: organiserDetails.profilePic,
      isPicPrefilled: organiserDetails.isProfilePicturePrefilled ? 1 : 0,
      socialMediaLinks: organiserDetails.socialMediaLinks,
    },
  };
};

const getRecurringPatternForPost = (recurringEvent: RecurringEvent) => {
  if (!recurringEvent.isRecurring) {
    return null;
  }
  return {
    recurring_type: recurringEvent.recurringType.toString(),
    recurring_end_date: getEventCreationTimeFormat(recurringEvent.recurringEndDate),
    recurring_weeks: recurringEvent.recurringWeeks ? recurringEvent.recurringWeeks.join(',') : null,
    recurring_month_weekday: recurringEvent.recurringMonthly?.weekday,
    recurring_month_weekday_num: recurringEvent.recurringMonthly?.weekdayNum,
  };
};

const getCoverPhotoData = (coverPhoto: CoverPhoto) => {
  if (coverPhoto.selectedTemplateHID) {
    return {
      poster_hid: coverPhoto.selectedTemplateHID,
    };
  }
  return {
    uploaded_url: coverPhoto.uploadedUrl,
  };
};

const getStyleData = (eventStyles: EventStyles) => {
  return {
    style: {
      colors: {
        primary: eventStyles.colors.primary,
        light: eventStyles.colors.light,
        lighter: eventStyles.colors.lighter,
        dark: eventStyles.colors.dark,
        darker: eventStyles.colors.darker,
      },
      font: eventStyles.fontFamily,
    },
  };
};

const getEventCreationTimeFormat = (dateTime: Date): string => {
  return dateTime.toLocaleString('en-us');
};

const prefillContentFromDesign = (eventVOMinifiedWizardPrefill: EventVOMinifiedWizardPrefill): void => {
  togglePreviewCoverPhotoLoading(true);
  togglePreviewTitleLoading(true);

  showLoading(LOADING_TOAST_KEY, {
    key: LOADING_TOAST_KEY,
    priority: 1,
    text: window.i18next.t('pmwjs_updating_details_with_ai'),
    hideIcon: true,
    progress: 0,
  });
  const estimateLoadingTimeMs = 5000;
  smoothEstimateLoadingProgress(LOADING_TOAST_KEY, estimateLoadingTimeMs, 500);

  const {dispatch} = window.PMW.redux.store;

  void window.PMW.writeLocal('event/getDesignReverseSemanticTaggingContent', {
    poster_hid: eventVOMinifiedWizardPrefill.designTemplateHID,
  })
    .then(setDesignContentFromReverseSemanticTagging)
    .catch((e: Error) => {
      hideLoading(LOADING_TOAST_KEY);

      if (e.message === 'limit_reached') {
        showMessageGrowl({
          key: MESSAGE_GROWL_KEY,
          text: window.i18next.t('pmwjs_daily_ai_generation_limit_reached'),
          interval: 15000,
          type: GROWL_TYPE.DANGER,
          showCloseIcon: true,
        });
      }
    })
    .finally(() => {
      dispatch(onEventWizardIsDesignPrefillContentLoadingUpdate(false));
      togglePreviewCoverPhotoLoading(false);
      togglePreviewTitleLoading(false);
    });
};

const setDesignContentFromReverseSemanticTagging = (eventVOMinifiedWizardPrefill: EventVOMinifiedWizardPrefill): void => {
  const {dispatch} = window.PMW.redux.store;

  if (eventVOMinifiedWizardPrefill.eventName) {
    dispatch(onEventWizardTitleChange(eventVOMinifiedWizardPrefill.eventName));
    updatePreviewTitle(eventVOMinifiedWizardPrefill.eventName);
  }

  if (eventVOMinifiedWizardPrefill.eventType) {
    dispatch(onEventWizardSelectedTypeChange(eventVOMinifiedWizardPrefill.eventType));
  }

  try {
    const startAndEndDateTime = updateDateTimeFromDesignPrefill(eventVOMinifiedWizardPrefill);

    if (startAndEndDateTime.length === 2) {
      eventVOMinifiedWizardPrefill.startDateTime = getSQLFormatDate(startAndEndDateTime[0]);
      eventVOMinifiedWizardPrefill.endDateTime = getSQLFormatDate(startAndEndDateTime[1]);
    }
  } catch (error) {
    console.error(error); // selfCodeReviewJibran: error
  }

  // selfCodeReviewJibran: Location needs handling. Hit gmap API and set. Attempt timezone too: // location?: string;

  if (isBasicSectionCompleteFromMinifiedVO(eventVOMinifiedWizardPrefill)) {
    dispatch(onEventWizardProgressStepUpdate(EventWizardProgressStep.REVIEW));
    dispatch(onEventWizardProgressStepLandedOnUpdate(EventWizardProgressStep.REVIEW));
  }
  updateLoadingProgress(LOADING_TOAST_KEY, 1.0);
  hideLoading(LOADING_TOAST_KEY);
};

const isBasicSectionCompleteFromMinifiedVO = (eventVOMinifiedWizardPrefill: EventVOMinifiedWizardPrefill): boolean => {
  if (
    !eventVOMinifiedWizardPrefill.eventName ||
    !eventVOMinifiedWizardPrefill.eventType ||
    !eventVOMinifiedWizardPrefill.startDateTime ||
    !eventVOMinifiedWizardPrefill.endDateTime
  ) {
    return false;
  }

  return isBasicSectionComplete(
    eventVOMinifiedWizardPrefill.eventName,
    eventVOMinifiedWizardPrefill.eventType,
    {}, // selfCodeReviewJibran: when gmap api ready. add in if statement above too
    new Date(eventVOMinifiedWizardPrefill.startDateTime),
    new Date(eventVOMinifiedWizardPrefill.endDateTime),
    getDefaultTimeZone()
  );
};

const updateDateTimeFromDesignPrefill = (eventVOMinifiedWizardPrefill: EventVOMinifiedWizardPrefill): Date[] => {
  if (eventVOMinifiedWizardPrefill.startDate && !eventVOMinifiedWizardPrefill.endDate) {
    eventVOMinifiedWizardPrefill.endDate = eventVOMinifiedWizardPrefill.startDate;
  }

  const startDateTime = getUpdateDateTimeFromDesignPrefill(getDefaultStartDateTime(), eventVOMinifiedWizardPrefill.startDate, eventVOMinifiedWizardPrefill.startTime);
  let endDateTime = getUpdateDateTimeFromDesignPrefill(getDefaultEndDateTime(), eventVOMinifiedWizardPrefill.endDate, eventVOMinifiedWizardPrefill.endTime);
  const timeZone = getDefaultTimeZone();

  if (eventVOMinifiedWizardPrefill.startTime && !eventVOMinifiedWizardPrefill.endTime) {
    endDateTime = addHoursToDate(startDateTime);
  }
  const startAndEndDateTime = attemptToSetReduxDateTimeFromGivenStartAndEnd(
    convertTimeFromTimezoneToUTC(startDateTime, timeZone.utc),
    convertTimeFromTimezoneToUTC(endDateTime, timeZone.utc),
    timeZone
  );

  if (startAndEndDateTime.length === 2) {
    updatePreviewDateTime({
      startDateTime: startAndEndDateTime[0],
      endDateTime: startAndEndDateTime[1],
      timeZone: timeZone,
    });
  }
  return startAndEndDateTime;
};

const getUpdateDateTimeFromDesignPrefill = (defaultDateTime: Date, updatedDate: string | undefined, updatedTime: string | undefined): Date => {
  if (!updatedTime && !updatedDate) {
    return defaultDateTime;
  }

  if (updatedDate) {
    const newStartDate = new Date(updatedDate);

    if (!isNaN(newStartDate.getTime())) {
      defaultDateTime.setFullYear(defaultDateTime.getFullYear(), newStartDate.getMonth(), newStartDate.getDate());
    }
  }

  if (updatedTime) {
    const [hours, minutes, _] = updatedTime.split(':').map(Number);

    if (isInteger(hours) && isInteger(minutes)) {
      defaultDateTime.setHours(hours, minutes, 0, 0);
    }
  }
  return defaultDateTime;
};
